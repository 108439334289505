import React from "react";
import {
  AiFillCustomerService,
  AiOutlineMail,
  AiFillHome,
  AiOutlineFileDone,
  AiOutlineCalendar,
  AiTwotoneCalendar,
  AiOutlineMobile,
  AiOutlineCloudDownload,
  AiOutlineMinus,
  AiOutlineCloudUpload,
  AiOutlineUser,
  AiFillWindows,
  AiOutlineCluster,
  AiTwotoneHome,
  AiTwotoneProject,
  AiFillUsb,
  AiOutlineGroup,
  AiOutlineCamera,
} from "react-icons/ai";

import { FaMapMarkerAlt, FaGlobe } from "react-icons/fa";

const Footer = () => {
  return (
    <div>
      <div className="row ">
        <div className="container ">
          <footer
            className="text-white text-center text-lg-start"
            style={{ backgroundColor: "#1597E5", bottom: "0" }}
          >
            <img
              style={{
                opacity: "0.7",

                margin: "1rem auto",
                position: "absolute",
                right: "0",
              }}
              width="600px"
              height="300px"
              src="https://gisupport.org/uploads/bgimages/worldmap.png"
              alt="pic map"
            ></img>
            <div className="container">
              <div className="row mt-4">
                <div className="col-lg-4 col-md-12 mb-4 mt-lg-3 mb-md-0 ">
                  <h3 className="font-weight-bold">
                    Global Impact Solutions Inc.
                  </h3>

                  <p>
                    Is a social cause based BPO company taking the lead on
                    educating companies on the inner workings and benefits of
                    Impact Sourcing and how they can most benefit from Impact
                    Sourcing.
                  </p>

                  <p>
                    Global Impact Solutions Inc. is a social cause based BPO
                    company taking the lead on educating companies on the inner
                    workings and benefits of Impact Sourcing and how they can
                    most benefit from Impact Sourcing.
                  </p>

                  <div className="mt-4">
                    {/* <a
                      href="https://www.facebook.com/GlobalImpactSourcingLLC"
                      type="button"
                      className="btn btn-floating btn-warning btn-lg"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>

                    <a
                      type="button"
                      className="btn btn-floating btn-warning btn-lg"
                    >
                      <i className="fab fa-dribbble"></i>
                    </a>

                    <a
                      type="button"
                      className="btn btn-floating btn-warning btn-lg"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>

                    <a
                      type="button"
                      className="btn btn-floating btn-warning btn-lg"
                    >
                      <i className="fab fa-google-plus-g"></i>
                    </a> */}
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4  mt-lg-3 mb-md-0">
                  <h5 className="text-uppercase mb-4 pb-1">
                    {/* Search something */}
                  </h5>

                  {/* <div
                    className="form-outline form-white mb-4"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      borderRadius: "10px",
                    }}
                  >
                    <label
                      className="form-label"
                      htmlFor="formControlLg"
                      style={{ marginLeft: "10px" }}
                    >
                      Search
                    </label>
                    <div className="form-notch">
                      <div
                        className="form-notch-leading"
                        style={{ width: "9px" }}
                      ></div>
                      <div
                        className="form-notch-middle"
                        style={{ width: "48.8px" }}
                      ></div>
                      <div className="form-notch-trailing"></div>
                    </div>
                  </div> */}

                  <ul className="fa-ul mb-4 ">
                    <li className="mb-3">
                      <span className="fa-li">
                        <FaMapMarkerAlt />
                      </span>
                      <p>
                        Cartalitz Building 2ndFloor Cagayan De Oro City PH 9000
                      </p>
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <FaGlobe />
                      </span>
                      <span>www.gissupport.org</span>
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-phone"></i>
                      </span>
                      <span>Tel:530-691-6032</span>
                    </li>
                    <li className="mb-3">
                      <span className="fa-li">
                        <i className="fas fa-print"></i>
                      </span>
                      <span className=" text-white font-weight-normal">
                        Fax:530-691-6032
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-4 col-md-6 mb-4 mt-lg-3 mb-md-0">
                  <h5 className="table  mb-4">Opening hours</h5>

                  <table className="table text-center text-white">
                    <tbody className="font-weight-normal">
                      <tr>
                        <td>Mon - Thu:</td>
                        <td>8am - 9pm</td>
                      </tr>
                      <tr>
                        <td>Fri - Sat:</td>
                        <td>8am - 1am</td>
                      </tr>
                      <tr>
                        <td>Sunday:</td>
                        <td>9am - 10pm</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="p-3 m-3">
                    <a
                      href="https://www.facebook.com/GlobalImpactSourcingLLC"
                      type="button"
                      className="btn btn-floating btn-primary btn-lg m-1"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>

                    <a
                      type="button"
                      className="btn btn-floating btn-primary btn-lg m-1"
                    >
                      <i className="fab fa-dribbble"></i>
                    </a>

                    <a
                      type="button"
                      className="btn btn-floating btn-primary btn-lg m-1"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>

                    <a
                      type="button"
                      className="btn btn-floating btn-primary btn-lg m-1"
                    >
                      <i className="fab fa-google-plus-g"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="text-center p-3"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
            >
              © 2021 Copyright:
              <a className="text-white" href="https://gisupport.org/">
                gisupport.org
              </a>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Footer;
