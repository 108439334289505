import { TablePagination } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
// import faker from "faker";
import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import "./Mtable.css";
import { Modal } from "antd";
import { TextField } from "@material-ui/core";
import "antd/dist/antd.css";

import { notification, Button } from "antd";
import { NavLink } from "react-router-dom";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
// import Button from "@restart/ui/esm/Button";
// import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "auto",
  },

  TableContainer: {
    borderRadius: 15,
    margin: "px 10px",
    maxWidth: "Auto",
    background: "-webkit-linear-gradient(left, #3931af, #00c6ff)",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontsize: "0.75rem",
    color: "white",
    backgroundColor: "Grey",
    padding: "3px 10px",
    display: "inline-block",
  },
}));

let USERS = [],
  STATUSES = ["Active", "Pending", "Blocked"];

// for (let i = 0; i < 14; i++) {
//   USERS[i] = {
//     name: faker.name.findName(),
//     email: faker.internet.email(),
//     phone: faker.phone.phoneNumber(),
//     jobTitle: faker.name.jobTitle(),
//     company: faker.company.companyName(),
//     joinDate: faker.date.past().toLocaleDateString("en-US"),
//     status: STATUSES[Math.floor(Math.random() * STATUSES.length)],
//   };
// }

function MTable() {
  const classes = useStyles();

  // const [searchdata, setSearchdata] = useState([]);
  // const handleFilter = (event) => {
  //   const searchWord = event.target.value;
  //   const newFilter = USERS.filter((value) => {
  //     return (
  //       value.name.toLowerCase().includes(searchWord.toLowerCase()) ||
  //       value.jobTitle.toLowerCase().includes(searchWord.toLowerCase())
  //     );
  //   });
  //   setSearchdata(newFilter);
  //   console.log("this is the new search word:", searchWord);
  //   console.log("this is the new filter word: ", newFilter[0]);
  // };
  //from admin view freelancers code

  const [Freelancers, setFreelancers] = useState([]);

  const [msgfromserver, setmsgfromserver] = useState("");
  const [modalisOpen, SetmodalisOpen] = useState(false);
  const [selectedItem, setSeletedItem] = useState("");
  const [forupdateUserType, setforupdateUserType] = useState("User");
  const [forupdateStatus, setforupdateStatus] = useState("User");

  const [searchdata, setSearchdata] = useState([]);
  const [Internetspeed, setInternetspeed] = useState("");

  useEffect(() => {
    axios({
      method: "get",
      url: "https://gisupport.org/backend/ViewAllFreelancers.php",
    }).then((response) => {
      console.log(response);
      setFreelancers(response.data);
    });
  }, []);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    const newFilter = Freelancers.filter((value) => {
      return (
        value.Firstname.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.Skills.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.Status.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.Middlename.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.Lastname.toLowerCase().includes(searchWord.toLowerCase())
      );
    });
    setSearchdata(newFilter);
    console.log("this is the searchword:", searchWord);
    console.log("this is the new filter: ", newFilter[0]);
  };
  const handleEdit = (e) => {
    // setSeletedItem(e.target.value);
    // console.log(e.target.value);
    SetmodalisOpen(true);
    console.log("this is the value");
  };

  const onChangeValue = (event) => {
    console.log(event.target.value);
    setforupdateUserType(event.target.value);
    console.log("for update value Usertype :", forupdateUserType);
  };
  const imageurl = "https://gisupport.org/uploads/";

  //for paganination codes

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(6);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [par1, setparameter] = useState("");
  const ToviewProfile = "/ViewProfile";

  // link to the "location"
  // see (https://reacttraining.com/react-router/web/api/location)

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [selectedFreelancer, setselectedFreelancer] = useState("");
  const [visible, setVisible] = useState(false);

  const [Sender, setsender] = useState(localStorage.getItem("yourusername"));
  const [Reciever, setReciever] = useState();
  const [Body, setBody] = useState();
  const [Subject, setSubject] = useState();

  const handlesend = () => {
    // event.preventDefault();

    let formData = new FormData();

    formData.append("Sender", Sender);
    formData.append("Reciever", selectedFreelancer);
    formData.append("Body", Body);
    formData.append("Subject", Subject);

    axios({
      method: "post",
      // url: "https://gisupport.org/backend/Uploadpicturefreelancer.php",
      url: "https://gisupport.org/backend/Messages.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        // const reply = setmsgfromserver(response.data);
        // setWhatdoyouwant("do you want to log in now?");
        console.log("response from server message :", response.data);
        console.log("mao ni form data", formData);
        if (response.data === "Successfully send") {
          openNotification();
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const openNotification = () => {
    notification.open({
      message: "Message from server",
      description: "Your message was succesfully sent",
      className: "custom-class",
      placement: "bottomRight",
      duration: 5,
      style: {
        width: 600,
      },
    });
  };
  return (
    <>
      <div className="ui search">
        <div className="ui icon input">
          <input
            type="text"
            placeholder="Search Freelancers"
            className="prompt"
            onChange={handleFilter}
          />
        </div>
      </div>
      <div>
        {searchdata.length == 0 ? (
          <div className="grid-container Fcodesdropshadow ">
            {Freelancers.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).map((row) => (
              <div
                key={row.UserID}
                className="cardm p-4 mt-3"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <div className="first">
                  <h6 className="heading"> {row.Skills}</h6>
                  <div className="time d-flex flex-row align-items-center justify-content-between mt-3">
                    <div className="d-flex align-items-center">
                      {" "}
                      <i className="fa fa-clock-o clock"></i>{" "}
                      <span className="hour ml-1">Hourly rate</span>{" "}
                    </div>
                    <div>
                      {" "}
                      <span className="font-weight-bold">
                        $ {row.HourlyRate}
                      </span>{" "}
                    </div>
                  </div>
                </div>
                <div className="second d-flex flex-row mt-2">
                  <div className="image freelancerImage mr-3 ">
                    <img
                      src={imageurl + row.ProfilePicture}
                      className="rounded-circle img-thumbnail"
                      style={{
                        width: "60px",
                        height: "60px",
                        objectFit: "cover",
                      }}
                    />
                    <div className="FreelancerStatus"></div>
                  </div>
                  <div className="">
                    <div className="d-flex flex-row mb-1">
                      {" "}
                      <span>
                        {`${row.Firstname} ${row.Middlename}
                          ${row.Lastname}`}
                      </span>
                      <div className="ratings ml-2">
                        <i className="fa fa-star"></i>{" "}
                        <i className="fa fa-star"></i>{" "}
                        <i className="fa fa-star"></i>{" "}
                        <i className="fa fa-star"></i>{" "}
                        <i className="fa fa-star"></i>{" "}
                      </div>
                    </div>
                    <div className="forcenteritems">
                      <Link
                        to={{
                          pathname: `/Webpages/${row.UserID}`,
                          state: {
                            SelectedTemplate: "3",
                            fromAdminView: true,
                          },
                        }}
                      >
                        <button
                          className="btn btn-light btn-sm"
                          value={row.EmailAddress}
                          name={row.EmailAddress}
                          onClick={(e) => {
                            setparameter(e.target.value);
                            console.log("this is the par1", par1);
                          }}
                        >
                          View Profile
                        </button>
                      </Link>

                      <button
                        className="btn btn-light btn-sm"
                        value={row.UserID}
                        onClick={(e) => {
                          setselectedFreelancer(row.UserID);
                          setReciever(selectedFreelancer);
                          setVisible(true);
                        }}
                      >
                        <i className="fa fa-comment-o"></i>
                      </button>
                    </div>
                  </div>
                </div>

                {/* <hr className="line-color"> */}
                <div className="forcenteritemscolumn">
                  <h6>Joined date: {row.Joindate}</h6>
                  <h6>Experienced: {row.Experience} years</h6>
                </div>
                <div className="third mt-4">
                  <button className="btn btn-primary btn-block">
                    <i className="fa fa-clock-o"></i> Hire now
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="grid-container Fcodesdropshadow OpacityInFreelancers ">
            {searchdata
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <div key={row.UserID} className="cardm p-4 mt-3 ">
                  <div className="first">
                    <h6 className="heading"> {row.Skills}</h6>
                    <div className="time d-flex flex-row align-items-center justify-content-between mt-3">
                      <div className="d-flex align-items-center">
                        {" "}
                        <i className="fa fa-clock-o clock"></i>{" "}
                        <span className="hour ml-1">Hourly rate</span>{" "}
                      </div>
                      <div>
                        {" "}
                        <span className="font-weight-bold">
                          $ {row.HourlyRate}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="second d-flex flex-row mt-2">
                    <div className="image freelancerImage  mr-3">
                      <img
                        src={imageurl + row.ProfilePicture}
                        className="rounded-circle img-thumbnail"
                        style={{
                          width: "60px",
                          height: "60px",
                          objectFit: "cover",
                        }}
                      />
                      <div className="FreelancerStatus"></div>
                    </div>
                    <div className="">
                      <div className="d-flex flex-row mb-1">
                        {" "}
                        <span>
                          {`${row.Firstname} ${row.Middlename}
                          ${row.Lastname}`}
                        </span>
                        <div className="ratings ml-2">
                          {" "}
                          <i className="fa fa-star"></i>{" "}
                          <i className="fa fa-star"></i>{" "}
                          <i className="fa fa-star"></i>{" "}
                          <i className="fa fa-star"></i>{" "}
                          <i className="fa fa-star"></i>{" "}
                        </div>
                      </div>
                      <div className="forcenteritems">
                        <button
                          className="btn btn-light btn-sm"
                          value={row.EmailAddress}
                          name={row.EmailAddress}
                          onClick={(e) => {
                            setparameter(e.target.value);
                            console.log("this is the par1", par1);
                          }}
                        >
                          <Link
                            to={{
                              pathname: `/Webpages/${row.UserID}`,
                              state: {
                                SelectedTemplate: "3",
                                fromAdminView: true,
                              },
                            }}
                          >
                            View Profile
                          </Link>
                        </button>

                        <button
                          className="btn btn-light btn-sm"
                          value={row.UserID}
                          onClick={(e) => {
                            setselectedFreelancer(row.UserID);
                            setReciever(selectedFreelancer);
                            setVisible(true);
                          }}
                        >
                          <i className="fa fa-comment-o"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* <hr className="line-color"> */}
                  <div className="forcenteritemscolumn">
                    <h6>Joined date: {row.Joindate}</h6>
                    <h6>Experienced: {row.Experience} years</h6>
                  </div>
                  <div className="third mt-4">
                    <button className="btn btn-primary btn-block">
                      <i className="fa fa-clock-o"></i> Hire now
                    </button>
                  </div>
                </div>
              ))}
          </div>
        )}
        {searchdata.length === 0 ? (
          <TablePagination
            rowsPerPageOptions={[6, 10, 15, 20]}
            component="div"
            count={Freelancers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : (
          <TablePagination
            rowsPerPageOptions={[6, 10, 15, 20]}
            component="div"
            count={searchdata.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </div>

      <Modal
        title="Message"
        centered
        visible={visible}
        okText="SEND"
        onOk={() => {
          setVisible(false);
          handlesend();
        }}
        onCancel={() => setVisible(false)}
        width={1000}
      >
        <p>TO: {selectedFreelancer}</p>
        <TextField
          style={{ width: "50%" }}
          autoFocus
          margin="dense"
          id="Subject"
          label="Subject "
          type="text"
          variant="standard"
          onChange={(e) => {
            setSubject(e.target.value);
          }}
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Type your message "
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => {
            setBody(e.target.value);
          }}
        />
      </Modal>
    </>
  );
}

export default MTable;
