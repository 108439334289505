import React from "react";
import { TextField, Button } from "@material-ui/core";
import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { notification } from "antd";

function Changepassword() {
  const [newpassword, setnewpassword] = useState();
  const [Confirmnewpassword, setConfirmnewpassword] = useState();
  const [clickSeepassword, setclickSeepassword] = useState(false);
  const [clickSeepasswordC, setclickSeepasswordC] = useState(false);
  const history = useHistory();

  const openNotification = () => {
    notification.open({
      message: "Congratulations",
      description: "Your account was successfully changed",
      className: "custom-class",
      placement: "bottomRight",
      duration: 5,
      style: {
        width: 600,
      },
    });
  };

  const openNotificationInvalid = () => {
    notification.open({
      message: "Sorry Password didn't match",
      description: "Please recheck your new password",
      className: "custom-class",
      placement: "bottomRight",
      duration: 5,
      style: {
        width: 600,
      },
    });
  };

  const handleChangepassword = (event) => {
    if (newpassword === Confirmnewpassword) {
      let formData = new FormData();

      formData.append("Newpassword", newpassword);
      formData.append("Username", localStorage.getItem("yourusername"));

      axios({
        method: "post",
        url: "https://gisupport.org/backend/Changepassword.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("response from forgot password", response);
          if (response.data.Msg === "success") {
            openNotification();
            history.push("/Profile");
          } else {
            openNotificationInvalid();
          }
        })
        .catch(function (response) {
          //handle error
          console.log("this is forgotpass catch", response);
        });
    } else {
      openNotificationInvalid();
    }
  };
  return (
    <div className="OpacityIn">
      <div
        style={{
          marginTop: "10rem",
          display: "flex",
          flexDirection: "column",

          borderRadius: "10px",
          backgroundColor: "#F7F7F7",
          placeItems: "center",
          alignContent: "center",
          margin: "10rem auto",
          width: "500px",
          height: "600px",
        }}
      >
        <TextField
          style={{
            display: "flex",
            placeItems: "center",
            alignContent: "center",
            alignSelf: "center",
            width: "300px",
            marginTop: "10rem",
            marginBottom: "2rem",
          }}
          label="Enter your new password"
          placeholder="New password"
          type={!clickSeepassword ? "password" : "text"}
          onChange={(e) => {
            setnewpassword(e.target.value);
          }}
          fullWidth
        />
        <span
          style={{
            position: "absolute",
            marginTop: "12rem",
            marginLeft: "20rem",
          }}
          className={
            !clickSeepassword ? "fa fa-fw fa-eye " : "fas fa-eye-slash"
          }
          onMouseEnter={() => {
            setclickSeepassword((clickSeepassword) => !clickSeepassword);
          }}
          onClick={() => {
            setclickSeepassword((clickSeepassword) => !clickSeepassword);
          }}
        ></span>
        <TextField
          style={{
            display: "flex",
            placeItems: "center",
            alignContent: "center",
            alignSelf: "center",
            width: "300px",
            marginBottom: "2rem",
          }}
          type={!clickSeepasswordC ? "password" : "text"}
          label="Enter your confirm new password"
          placeholder="Confirm new password"
          onChange={(e) => {
            setConfirmnewpassword(e.target.value);
          }}
          fullWidth
        />{" "}
        <span
          // className="OpacityIn"
          style={{
            position: "absolute",
            marginTop: "17rem",
            marginLeft: "20rem",
          }}
          className={
            !clickSeepasswordC ? "fa fa-fw fa-eye " : "fas fa-eye-slash"
          }
          onMouseEnter={() => {
            setclickSeepasswordC((clickSeepasswordC) => !clickSeepasswordC);
          }}
          onClick={() => {
            setclickSeepasswordC((clickSeepasswordC) => !clickSeepasswordC);
          }}
        ></span>
        <Button
          variant="contained"
          onClick={() => {
            handleChangepassword();
          }}
        >
          SAVE
        </Button>
      </div>
    </div>
  );
}

export default Changepassword;
