import React from "react";

import "antd/dist/antd.css";
import "./CreateMessage.css";

import { Input } from "antd";
import axios from "axios";
import { useState } from "react";
import { TextField } from "@material-ui/core";
import "antd/dist/antd.css";

import { notification } from "antd";
import { red } from "@material-ui/core/colors";

function CreateMessage() {
  const TextArea = Input;

  const onChange = (e) => {
    console.log("Change:", e.target.value);
  };
  const [To, setTo] = useState("");
  const [visible, setVisible] = useState(false);

  const [Sender, setsender] = useState(localStorage.getItem("yourusername"));
  const [Reciever, setReciever] = useState();
  const [Body, setBody] = useState();
  const [Subject, setSubject] = useState();

  const checkFields = () => {
    if (To === "" || Sender === "" || Body === "" || Subject === "") {
      openNotificationFailed();
    } else {
      handlesend();
    }
  };

  const handlesend = () => {
    // event.preventDefault();

    let formData = new FormData();

    formData.append("Sender", Sender);
    formData.append("Reciever", To);
    formData.append("Body", Body);
    formData.append("Subject", Subject);

    axios({
      method: "post",
      // url: "https://gisupport.org/backend/Uploadpicturefreelancer.php",
      url: "https://gisupport.org/backend/Messages.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        // const reply = setmsgfromserver(response.data);
        // setWhatdoyouwant("do you want to log in now?");
        console.log("response from server message :", response.data);
        console.log("mao ni form data", formData);
        if (response.data === "Successfully send") {
          openNotification();
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };
  const openNotificationFailed = () => {
    notification.open({
      message: "Message failed",
      description:
        "Your message was unsuccesfull cause, you must fill all fields",
      className: "custom-class",
      placement: "bottomRight",
      duration: 5,
      style: {
        width: 600,
        color: "red",
      },
    });
  };

  const openNotification = () => {
    notification.open({
      message: "Message from server",
      description: "Your message was succesfully sent",
      className: "custom-class",
      placement: "bottomRight",
      duration: 5,
      style: {
        width: 600,
      },
    });
  };
  return (
    <div className="container formCreateMessage ">
      <form className="row" style={{ display: "block" }}>
        {/* <div className="row">
          <input type="text" placeholder="To:" type="text" cols="40" />
        </div>
        <div className="row">
          {" "}
          <input type="text" placeholder="Subject" />
        </div>
        <div className="row">
          <textarea
            type="text"
            placeholder="Body"
            style={{ height: "300px", width: "80vh" }}
          />
        </div>
        <div className="row">
          <input type="button" value="Send" />
        </div> */}
        <input
          type="text"
          placeholder="To:"
          type="text"
          cols="40"
          style={{ width: "300px" }}
          onChange={(e) => {
            setTo(e.target.value);
          }}
        />
        <br />
        <input
          type="text"
          placeholder="Subject"
          style={{ width: "300px" }}
          onChange={(e) => {
            setBody(e.target.value);
          }}
        />{" "}
        <br />
        <br />
        <textarea
          type="text"
          placeholder="Type your message....."
          style={{ height: "300px", width: "80vh" }}
          onChange={(e) => {
            setBody(e.target.value);
          }}
        />
        <br />
        <input
          className="grow"
          type="button"
          value="Send"
          style={{ width: "300px" }}
          onClick={() => {
            checkFields();
          }}
        />
      </form>
    </div>
  );
}

export default CreateMessage;
