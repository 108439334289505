import {
  Avatar,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableFooter,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import CreateIcon from "@material-ui/icons/Create";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import ReactModal from "react-modal";
import { Modal } from "antd";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 450,
    maxWidth: "100%",
  },

  TableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "auto",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    width: "300px",
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontsize: "0.75rem",
    color: "white",
    backgroundColor: "Grey",
    padding: "3px 10px",
    display: "inline-block",
    borderRadius: "50px",
  },
  searchbar: {
    fontWeight: "bold",
    fontsize: "0.75rem",
    borderRadius: 15,
    color: "white",
    backgroundColor: "White",
    padding: "-1px 10px",
    display: "inline-block",
    marginLeft: "10px",
  },
  pantay: {
    display: "flex",

    alignItems: "center",
  },
}));

// let USERS = [],
//   STATUSES = ["Active", "Pending", "Blocked"];

// for (let i = 0; i < 14; i++) {
//   USERS[i] = {
//     name: faker.name.findName(),
//     email: faker.internet.email(),
//     phone: faker.phone.phoneNumber(),
//     jobTitle: faker.name.jobTitle(),
//     company: faker.company.companyName(),
//     joinDate: faker.date.past().toLocaleDateString("en-US"),
//     status: STATUSES[Math.floor(Math.random() * STATUSES.length)],
//   };
// }

function Sent() {
  const [selectedFreelancer, setselectedFreelancer] = useState("");
  const [visible, setVisible] = useState(false);

  const [Sender, setsender] = useState(localStorage.getItem("yourusername"));
  const [Reciever, setReciever] = useState();
  const [Body, setBody] = useState();
  const [Subject, setSubject] = useState();

  const [Inbox, setInbox] = useState([]);
  const [searchdata, setSearchdata] = useState([]);

  const [msgfromserver, setmsgfromserver] = useState("");
  const [modalisOpen, SetmodalisOpen] = useState(false);
  const [selectedItem, setSeletedItem] = useState("");
  const [forupdateUserType, setforupdateUserType] = useState("User");
  const [forupdateStatus, setforupdateStatus] = useState("User");

  const [selectedUser, setSelectedUser] = useState();
  const [activity, setActivity] = useState();
  const [activityType, setactivityType] = useState("1");
  const [username, setusername] = useState(
    localStorage.getItem("yourusername")
  );
  const [selectedbody, setselectedbody] = useState();
  const [selectedstatus, setselectedstatus] = useState();
  const [selectedSender, setselectedSender] = useState();
  const [selectedDatetime, setselectedDateTime] = useState();
  const [selectedReciever, setselectedReciever] = useState();

  const modalStyle = {
    content: {
      backgroundColor: "#FFFF",
      padding: "50px",
      zIndex: 1000,
      width: "70%",
      margin: "auto auto",
      height: "15rem",
      maxWidth: "30rem",
      opacity: 0.9,
      boxShadow: "rgba(0,0,0,0.5) 0px 0px 24px",
      borderRadius: "20px",
    },
  };

  const closebtn = {
    padding: 0,
    margin: 0,
    position: "absolute",
    right: 0,
    top: 0,
  };

  const classes = useStyles();

  useEffect(() => {
    let formData = new FormData();

    // formData.append("email", selectedItem);
    // formData.append("selectedUser", selectedUser);
    // formData.append("activity", activity);
    // formData.append("UserType", forupdateUserType);
    // formData.append("activityType", activityType);
    const messagetype = "Sent";
    formData.append("username", username.trim());
    formData.append("messagetype", messagetype);
    axios({
      method: "post",
      url: "https://gisupport.org/backend/ViewMessages.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then((response) => {
      console.log("this is the Viewmessage php ", response);
      if (response.data.Record === "0") {
        setInbox([]);
      } else {
        setInbox(response.data);
      }
    });
  }, []);

  //
  // start of search code

  // function searchForm(event) {
  //   event.preventDefault();

  //   let formData = new FormData();
  //   formData.append("searchdata", searchdata);

  //   axios({
  //     method: "post",
  //     url: "https://gisupport.org/backend/Searchusers.php",
  //     data: formData,
  //     config: { headers: { "Content-Type": "multipart/form-data" } },
  //   })
  //     .then(function (response) {
  //       //handle success
  //       //
  //       //
  //       if (response.data.length) {
  //         setUsers(response.data);
  //         console.log(response.data);
  //       } else if (response.data == "walang data") {
  //         console.log(response.data);
  //         setUsers([]);
  //       }
  //       console.log(response);
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log("shakoy this is catch: " + response);
  //       setUsers([]);
  //     });
  // }
  const handleupdateSelected = (event) => {
    let formData = new FormData();

    formData.append("email", selectedItem);
    formData.append("Userstatus", forupdateStatus);
    formData.append("UserType", forupdateUserType);

    axios({
      method: "post",
      url: "https://gisupport.org/backend/updateuser.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then(function (response) {
      //handle success
      console.log(response);
    });
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    const newFilter = Inbox.filter((value) => {
      return (
        value.Sender.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.Status.toLowerCase().includes(searchWord.toLowerCase())
      );
      // value.EmailAddress.toLowerCase().includes(searchWord.toLowerCase())
    });
    setSearchdata(newFilter);
    console.log("this is the searchword:", searchWord);
    console.log("this is the new filter: ", newFilter[0]);
  };

  const handleEdit = (e) => {
    // setSeletedItem(e.target.value);
    // console.log(e.target.value);
    SetmodalisOpen(true);
    console.log("this is the value");
  };

  const onChangeValue = (event) => {
    console.log(event.target.value);
    setforupdateUserType(event.target.value);
    console.log("for update value Usertype :", forupdateUserType);
  };

  // for Pagination code
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const saveActivity = (event) => {
    let formData = new FormData();

    // formData.append("email", selectedItem);
    // formData.append("selectedUser", selectedUser);
    // formData.append("activity", activity);
    // formData.append("UserType", forupdateUserType);
    // formData.append("activityType", activityType);
    formData.append("username", username);

    axios({
      method: "post",
      url: "https://gisupport.org/backend/ViewMessages.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then(function (response) {
      //handle success
      console.log("this is the activitie response", response);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [selectedMessageID, setselectedMessageID] = useState("initialState");
  return (
    <>
      <div>
        <div className="ui search">
          <div className="ui icon input">
            <input
              type="text"
              placeholder="Search.."
              className="prompt"
              onChange={handleFilter}
            />
            <i className="search icon"> </i>
          </div>
        </div>
        <TableContainer component={Paper} className={classes.TableContainer}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell}>
                  <div className="pantay">
                    <div>Reciever</div>
                  </div>
                </TableCell>

                <TableCell className={classes.tableHeaderCell}>
                  Message Body
                </TableCell>

                <TableCell className={classes.tableHeaderCell}>
                  Date time sent
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>View</TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            {searchdata.length === 0 ? (
              <>
                <TableBody>
                  {Inbox.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((row) => (
                    <TableRow
                      key={row.Record}
                      className="growInbox"
                      onClick={() => {
                        setselectedbody(row.Body);
                        setselectedReciever(row.Reciever);
                        setselectedDateTime(row.DateTime);
                        setselectedMessageID(row.Record);
                        setVisible(true);
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Grid container>
                          <Grid item lg={2}>
                            <Avatar
                              alt={row.Reciever}
                              src="."
                              className={classes.avatar}
                            />
                          </Grid>
                          <Grid item lg={10}>
                            <Typography color="textSecondary" variant="body2">
                              {row.Reciever}
                            </Typography>
                            {/* <Typography>{row.EmailAddress}</Typography> */}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary" variant="body2">
                          {row.Body}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {/* {row.company} */}
                        </Typography>
                      </TableCell>

                      <TableCell color="textSecondary" variant="body2">
                        <Typography
                        // className={classes.status}
                        // style={{
                        //   backgroundColor:
                        //     (row.Status === "Active" && "green") ||
                        //     (row.Status === "Pending" && "blue") ||
                        //     (row.Status === "Blocked" && "red"),
                        // }}
                        >
                          {row.DateTime}
                        </Typography>
                      </TableCell>
                      <TableCell color="textSecondary" variant="body2">
                        <Typography>
                          <Button
                            value={row.EmailAddress}
                            onClick={(e) => {
                              setselectedbody(row.Body);
                              setselectedReciever(row.Reciever);
                              setselectedDateTime(row.DateTime);
                              setselectedMessageID(row.Record);

                              setVisible(true);
                            }}
                          >
                            <CreateIcon />
                          </Button>
                        </Typography>
                      </TableCell>
                      <TableCell color="textSecondary" variant="body2">
                        <Typography
                          className={classes.status}
                          style={{
                            backgroundColor:
                              (row.Status === "Read" && "green") ||
                              (row.Status === "Unread" && "red"),
                          }}
                        >
                          {row.Status}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            ) : (
              <TableBody>
                {searchdata
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      key={row.Record}
                      className="growInbox"
                      onClick={() => {
                        setselectedbody(row.Body);
                        setselectedReciever(row.Reciever);
                        setselectedDateTime(row.DateTime);
                        setselectedMessageID(row.Record);
                        setVisible(true);
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Grid container>
                          <Grid item lg={2}>
                            <Avatar
                              alt={row.Sender}
                              src="."
                              className={classes.avatar}
                            />
                          </Grid>
                          <Grid item lg={10}>
                            <Typography color="textSecondary" variant="body2">
                              {row.Sender}
                            </Typography>
                            {/* <Typography>{row.EmailAddress}</Typography> */}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary" variant="body2">
                          {row.Body}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {/* {row.company} */}
                        </Typography>
                      </TableCell>

                      <TableCell color="textSecondary" variant="body2">
                        <Typography
                        // className={classes.status}
                        // style={{
                        //   backgroundColor:
                        //     (row.Status === "Active" && "green") ||
                        //     (row.Status === "Pending" && "blue") ||
                        //     (row.Status === "Blocked" && "red"),
                        // }}
                        >
                          {row.DateTime}
                        </Typography>
                      </TableCell>
                      <TableCell color="textSecondary" variant="body2">
                        <Typography>
                          <Button
                            value={row.EmailAddress}
                            onClick={(e) => {
                              setselectedbody(row.Body);
                              setselectedReciever(row.Reciever);
                              setselectedDateTime(row.DateTime);
                              setselectedMessageID(row.Record);
                              setVisible(true);
                            }}
                          >
                            <CreateIcon />
                          </Button>
                        </Typography>
                      </TableCell>
                      <TableCell color="textSecondary" variant="body2">
                        <Typography
                          className={classes.status}
                          style={{
                            backgroundColor:
                              (row.Status === "Read" && "green") ||
                              (row.Status === "Unread" && "red"),
                          }}
                        >
                          {row.Status}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {/* <button onClick={() => alert(selectedMessageID)}>
          view selected message ID
        </button> */}
        {searchdata.length === 0 ? (
          <TableFooter style={{ width: "auto" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20]}
              component="div"
              count={Inbox.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableFooter>
        ) : (
          <TableFooter style={{ width: "auto" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20]}
              component="div"
              count={searchdata.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableFooter>
        )}

        <Modal
          title="Message"
          centered
          visible={visible}
          okText="SEND"
          onOk={() => {
            setVisible(false);
          }}
          onCancel={() => setVisible(false)}
          width={1000}
        >
          <p>To: {selectedReciever}</p>
          <Typography>{selectedbody}</Typography>
          <span style={{ opacity: ".7", fontsize: "11px" }}>
            {selectedDatetime}
          </span>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Type your reply "
            type="text"
            fullWidth
            variant="standard"
            // onChange={(e) => {
            //   setBody(e.target.value);
            // }}
          />
        </Modal>
      </div>
    </>
  );
}

export default Sent;
