import React from "react";
import Videobackground from "../components/video/Videobackground.mp4";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { typography } from "@material-ui/system";
import { left } from "@popperjs/core";
import { NavLink } from "react-router-dom";
import MTable from "../components/MTable";
import { Component, props } from "react";
import "./index.css";
import HomeNew from "./HomeNew";
import Landingpage from "./Landingpage";

export default class Home extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        {/* <HomeNew /> */}

        <Landingpage />
      </>
    );
  }
}
