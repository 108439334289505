import {
  Avatar,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableFooter,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CreateIcon from "@material-ui/icons/Create";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import ReactModal from "react-modal";

import * as ContextMenu from "@radix-ui/react-context-menu";
import SideNav from "./AdminDashboardv2/Components/SideNav";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 450,
    maxWidth: "100%",
  },

  TableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "auto",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    width: "300px",
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontsize: "0.75rem",
    color: "white",
    backgroundColor: "Grey",
    padding: "3px 10px",
    display: "inline-block",
    borderRadius: "50px",
  },
  searchbar: {
    fontWeight: "bold",
    fontsize: "0.75rem",
    borderRadius: 15,
    color: "white",
    backgroundColor: "White",
    padding: "-1px 10px",
    display: "inline-block",
    marginLeft: "10px",
  },
  pantay: {
    display: "flex",

    alignItems: "center",
  },
}));

// let USERS = [],
//   STATUSES = ["Active", "Pending", "Blocked"];

// for (let i = 0; i < 14; i++) {
//   USERS[i] = {
//     name: faker.name.findName(),
//     email: faker.internet.email(),
//     phone: faker.phone.phoneNumber(),
//     jobTitle: faker.name.jobTitle(),
//     company: faker.company.companyName(),
//     joinDate: faker.date.past().toLocaleDateString("en-US"),
//     status: STATUSES[Math.floor(Math.random() * STATUSES.length)],
//   };
// }

function Users() {
  const [users, setUsers] = useState([]);
  const [searchdata, setSearchdata] = useState([]);

  const [msgfromserver, setmsgfromserver] = useState("");
  const [modalisOpen, SetmodalisOpen] = useState(false);
  const [selectedItem, setSeletedItem] = useState("");
  const [forupdateUserType, setforupdateUserType] = useState("User");
  const [forupdateStatus, setforupdateStatus] = useState("User");

  const modalStyle = {
    content: {
      backgroundColor: "#FFFF",
      padding: "50px",
      zIndex: 1000,
      width: "70%",
      margin: "auto auto",
      height: "15rem",
      maxWidth: "30rem",
      opacity: 0.9,
      boxShadow: "rgba(0,0,0,0.5) 0px 0px 24px",
      borderRadius: "20px",
    },
  };

  const closebtn = {
    padding: 0,
    margin: 0,
    position: "absolute",
    right: 0,
    top: 0,
  };
  const [viewClick, setviewClick] = useState(false);

  const classes = useStyles();
  useEffect(() => {
    axios({
      method: "get",
      url: "https://gisupport.org/backend/Viewusers.php",
    }).then((response) => {
      console.log(response);
      setUsers(response.data);
    });
  }, [viewClick]);

  //
  // start of search code

  // function searchForm(event) {
  //   event.preventDefault();

  //   let formData = new FormData();
  //   formData.append("searchdata", searchdata);

  //   axios({
  //     method: "post",
  //     url: "https://gisupport.org/backend/Searchusers.php",
  //     data: formData,
  //     config: { headers: { "Content-Type": "multipart/form-data" } },
  //   })
  //     .then(function (response) {
  //       //handle success
  //       //
  //       //
  //       if (response.data.length) {
  //         setUsers(response.data);
  //         console.log(response.data);
  //       } else if (response.data == "walang data") {
  //         console.log(response.data);
  //         setUsers([]);
  //       }
  //       console.log(response);
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log("shakoy this is catch: " + response);
  //       setUsers([]);
  //     });
  // }
  const handleupdateSelected = (event) => {
    let formData = new FormData();

    formData.append("email", selectedItem);
    formData.append("Userstatus", forupdateStatus);
    formData.append("UserType", forupdateUserType);

    axios({
      method: "post",
      url: "https://gisupport.org/backend/updateuser.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then(function (response) {
      //handle success
      console.log(response);
    });
  };

  const handleDeactivateSelected = (event) => {
    let formData = new FormData();

    formData.append("email", selectedItem);
    formData.append("Userstatus", forupdateStatus);
    formData.append("UserType", forupdateUserType);

    axios({
      method: "post",
      url: "https://gisupport.org/backend/DeactivateUser.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then(function (response) {
      //handle success
      console.log(response);
    });
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    const newFilter = users.filter((value) => {
      return (
        value.Username.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.Status.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.EmailAddress.toLowerCase().includes(searchWord.toLowerCase())
      );
    });
    setSearchdata(newFilter);
    console.log("this is the searchword:", searchWord);
    console.log("this is the new filter: ", newFilter[0]);
  };

  const handleEdit = (e) => {
    // setSeletedItem(e.target.value);
    // console.log(e.target.value);
    SetmodalisOpen(true);
    console.log("this is the value");
  };

  const onChangeValue = (event) => {
    console.log(event.target.value);
    setforupdateUserType(event.target.value);
    console.log("for update value Usertype :", forupdateUserType);
  };

  // for Pagination code
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [selectedUser, setSelectedUser] = useState();
  const [activity, setActivity] = useState();
  const [activityType, setactivityType] = useState("1");
  const [username, setusername] = useState(
    localStorage.getItem("yourusername")
  );
  const saveActivity = (event) => {
    let formData = new FormData();

    formData.append("email", selectedItem);
    formData.append("selectedUser", selectedUser);
    formData.append("activity", activity);
    formData.append("UserType", forupdateUserType);
    formData.append("activityType", activityType);
    formData.append("username", username);

    axios({
      method: "post",
      url: "https://gisupport.org/backend/UserActivities.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then(function (response) {
      //handle success
      console.log("this is the activitie response", response);
    });
  };

  const saveDeactivateActivity = (event) => {
    let formData = new FormData();

    formData.append("email", selectedItem);
    formData.append("selectedUser", selectedUser);
    formData.append("activity", "Deactivated user");
    formData.append("UserType", forupdateUserType);
    formData.append("activityType", activityType);
    formData.append("username", username);

    axios({
      method: "post",
      url: "https://gisupport.org/backend/UserActivities.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then(function (response) {
      //handle success
      console.log("this is the activitie response", response);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <SideNav />
      <div className="container">
        {" "}
        <div
          style={{ marginTop: "7rem" }}
          className="row d-flex justify-content-center ml-3 align-items-center"
        >
          <div className="ui search">
            <div className="ui icon input">
              <input
                type="text"
                placeholder="Search.."
                className="prompt"
                onChange={handleFilter}
              />
              <i className="search icon"> </i>
            </div>
          </div>
          <TableContainer component={Paper} className={classes.TableContainer}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell}>
                    <div className="pantay">
                      <div>Username</div>
                    </div>
                  </TableCell>

                  <TableCell className={classes.tableHeaderCell}>
                    UserType
                  </TableCell>

                  <TableCell className={classes.tableHeaderCell}>
                    Status
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    EDIT
                  </TableCell>
                </TableRow>
              </TableHead>
              {searchdata.length === 0 ? (
                <>
                  <TableBody>
                    {users
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow
                          key={row.Username}
                          className="growInbox"
                          onClick={() => {
                            setSeletedItem(row.EmailAddress);
                            setSelectedUser(row.Username);
                            SetmodalisOpen(true);
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Grid container>
                              <Grid item lg={2}>
                                <Avatar
                                  alt={row.Username}
                                  src="."
                                  className={classes.avatar}
                                />
                              </Grid>
                              <Grid item lg={10}>
                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                >
                                  {row.Username}
                                </Typography>
                                <Typography>{row.EmailAddress}</Typography>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <Typography color="textSecondary" variant="body2">
                              {row.UserType}
                            </Typography>
                            <Typography color="textSecondary" variant="body2">
                              {/* {row.company} */}
                            </Typography>
                          </TableCell>

                          <TableCell color="textSecondary" variant="body2">
                            <Typography
                              className={classes.status}
                              style={{
                                backgroundColor:
                                  (row.Status === "Active" && "green") ||
                                  (row.Status === "Pending" && "blue") ||
                                  (row.Status === "Blocked" && "red"),
                              }}
                            >
                              {row.Status}
                            </Typography>
                          </TableCell>
                          <TableCell color="textSecondary" variant="body2">
                            <Typography>
                              <Button
                                value={row.EmailAddress}
                                className="growInbox"
                                onClick={(e) => {
                                  setSeletedItem(row.EmailAddress);
                                  setSelectedUser(row.Username);
                                  SetmodalisOpen(true);
                                  console.log(row.EmailAddress);
                                }}
                              >
                                <CreateIcon />
                              </Button>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </>
              ) : (
                <TableBody>
                  {searchdata
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={row.Username}
                        onClick={() => {
                          setSeletedItem(row.EmailAddress);
                          setSelectedUser(row.Username);
                          SetmodalisOpen(true);
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Grid container>
                            <Grid item lg={2}>
                              <Avatar
                                alt={row.Username}
                                src="."
                                className={classes.avatar}
                              />
                            </Grid>
                            <Grid item lg={10}>
                              <Typography color="textSecondary" variant="body2">
                                {row.Username}
                              </Typography>
                              <Typography>{row.EmailAddress}</Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Typography color="textSecondary" variant="body2">
                            {row.UserType}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            variant="body2"
                          ></Typography>
                        </TableCell>

                        <TableCell color="textSecondary" variant="body2">
                          <Typography
                            className={classes.status}
                            style={{
                              backgroundColor:
                                (row.Status === "Active" && "green") ||
                                (row.Status === "Pending" && "blue") ||
                                (row.Status === "Blocked" && "red"),
                            }}
                          >
                            {row.Status}
                          </Typography>
                        </TableCell>
                        <TableCell color="textSecondary" variant="body2">
                          <Typography>
                            <Button
                              value={row.EmailAddress}
                              onClick={(e) => {
                                setSeletedItem(row.EmailAddress);
                                setSelectedUser(row.Username);
                                SetmodalisOpen(true);
                                console.log(row.EmailAddress);
                              }}
                            >
                              <CreateIcon />
                            </Button>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {searchdata.length === 0 ? (
            <TableFooter style={{ width: "auto" }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableFooter>
          ) : (
            <TableFooter style={{ width: "auto" }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20]}
                component="div"
                count={searchdata.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableFooter>
          )}

          <ReactModal
            style={modalStyle}
            isOpen={modalisOpen}
            onRequestClose={() => SetmodalisOpen(false)}
          >
            <Button style={closebtn} onClick={() => SetmodalisOpen(false)}>
              <FaTimes />
            </Button>
            {/* <h3>{msgfromserver}</h3> */}
            <h6>{selectedItem}</h6>

            <div onChange={onChangeValue}>
              <input type="radio" value="Admin" name="userType" />
              Admin
              <input type="radio" value="User" name="userType" /> User
            </div>
            <Button
              onClick={(e) => {
                handleDeactivateSelected();
                saveDeactivateActivity();
                SetmodalisOpen(false);
                setviewClick((viewClick) => !viewClick);
              }}
            >
              {" "}
              Deactivate
            </Button>
            <Button
              onClick={(e) => {
                handleupdateSelected();
                saveActivity();
                SetmodalisOpen(false);
                setviewClick((viewClick) => !viewClick);
              }}
            >
              {" "}
              Update
            </Button>
          </ReactModal>
        </div>
      </div>
    </>
  );
}

export default Users;
