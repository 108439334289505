import React from "react";

function LiveDemo(link) {
  return (
    <>
      <iframe
        // src={link}
        src="www.bootstrapmade.com/demo/DevFolio"
        style={{
          margin: "4.3% auto",

          alignItems: "center",
          height: "90vh",
          width: "100%",
        }}
        title="Gitime"
      ></iframe>
    </>
  );
}

export default LiveDemo;
