import {
  Avatar,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableFooter,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CreateIcon from "@material-ui/icons/Create";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import ReactModal from "react-modal";

import { AiOutlineEye } from "react-icons/ai";
import SideNav from "./AdminDashboardv2/Components/SideNav";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 450,
    maxWidth: "100%",
  },

  TableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: "auto",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    width: "300px",
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  status: {
    fontWeight: "bold",
    fontsize: "0.75rem",
    color: "white",
    backgroundColor: "Grey",
    padding: "3px 10px",
    display: "inline-block",
    borderRadius: "50px",
  },
  searchbar: {
    fontWeight: "bold",
    fontsize: "0.75rem",
    borderRadius: 15,
    color: "white",
    backgroundColor: "White",
    padding: "-1px 10px",
    display: "inline-block",
    marginLeft: "10px",
  },
  pantay: {
    display: "flex",

    alignItems: "center",
  },
}));

// let USERS = [],
//   STATUSES = ["Active", "Pending", "Blocked"];

// for (let i = 0; i < 14; i++) {
//   USERS[i] = {
//     name: faker.name.findName(),
//     email: faker.internet.email(),
//     phone: faker.phone.phoneNumber(),
//     jobTitle: faker.name.jobTitle(),
//     company: faker.company.companyName(),
//     joinDate: faker.date.past().toLocaleDateString("en-US"),
//     status: STATUSES[Math.floor(Math.random() * STATUSES.length)],
//   };
// }

function UserActivities() {
  const [activity, setactivity] = useState([]);
  const [searchdata, setSearchdata] = useState([]);

  const [msgfromserver, setmsgfromserver] = useState("");
  const [modalisOpen, SetmodalisOpen] = useState(false);
  const [selectedItem, setSeletedItem] = useState("");
  const [forupdateUserType, setforupdateUserType] = useState("User");
  const [forupdateStatus, setforupdateStatus] = useState("User");

  const modalStyle = {
    content: {
      backgroundColor: "#FFFF",
      padding: "50px",
      zIndex: 1000,
      width: "70%",
      margin: "auto auto",
      height: "15rem",
      maxWidth: "30rem",
      opacity: 0.9,
      boxShadow: "rgba(0,0,0,0.5) 0px 0px 24px",
      borderRadius: "20px",
    },
  };

  const closebtn = {
    padding: 0,
    margin: 0,
    position: "absolute",
    right: 0,
    top: 0,
  };

  const classes = useStyles();
  useEffect(() => {
    axios({
      method: "get",
      url: "https://gisupport.org/backend/UserActivities.php",
    }).then((response) => {
      console.log(response);
      setactivity(response.data);
    });
  }, []);

  //
  // start of search code

  // function searchForm(event) {
  //   event.preventDefault();

  //   let formData = new FormData();
  //   formData.append("searchdata", searchdata);

  //   axios({
  //     method: "post",
  //     url: "https://gisupport.org/backend/Searchusers.php",
  //     data: formData,
  //     config: { headers: { "Content-Type": "multipart/form-data" } },
  //   })
  //     .then(function (response) {
  //       //handle success
  //       //
  //       //
  //       if (response.data.length) {
  //         setUsers(response.data);
  //         console.log(response.data);
  //       } else if (response.data == "walang data") {
  //         console.log(response.data);
  //         setUsers([]);
  //       }
  //       console.log(response);
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log("shakoy this is catch: " + response);
  //       setUsers([]);
  //     });
  // }
  const handleupdateSelected = (event) => {
    let formData = new FormData();

    formData.append("email", selectedItem);
    formData.append("Userstatus", forupdateStatus);
    formData.append("UserType", forupdateUserType);

    axios({
      method: "post",
      url: "https://gisupport.org/backend/updateuser.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then(function (response) {
      //handle success
      console.log(response);
    });
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    const newFilter = activity.filter((value) => {
      return (
        value.UserID.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.Activity.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.Datetime.toLowerCase().includes(searchWord.toLowerCase())
      );
    });
    setSearchdata(newFilter);
    console.log("this is the searchword:", searchWord);
    console.log("this is the new filter: ", newFilter[0]);
  };
  const handleEdit = (e) => {
    // setSeletedItem(e.target.value);
    // console.log(e.target.value);
    SetmodalisOpen(true);
    console.log("this is the value");
  };

  const onChangeValue = (event) => {
    console.log(event.target.value);
    setforupdateUserType(event.target.value);
    console.log("for update value Usertype :", forupdateUserType);
  };
  // for Pagination code
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [selectedActivity, setSelectedActivity] = useState("activity logs");
  const [selectedDattime, setselecteddatetime] = useState("Selected DATE TIME");

  const style = {
  container: {
  marginTop: "5rem",
    '@media (min-width: 968px)': {
      backgroundColor: 'red',
      padding: '40px'
    }
  }
};

  return (
    <div className="container" style={style.container}>
    <SideNav/>
 


      <div   className="row  d-flex justify-content-center ml-3 align-items-center">
        <div className="ui search">
          <div className="ui icon input">
            <input
              type="text"
              placeholder="Search.."
              className="prompt"
              onChange={handleFilter}
            />
            <i className="search icon"> </i>
          </div>
        </div>
        <TableContainer component={Paper} className={classes.TableContainer}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell}>
                  <div className="pantay">
                    <div>Username</div>
                  </div>
                </TableCell>

                {/* <TableCell className={classes.tableHeaderCell}>
                  UserType
                </TableCell> */}

                <TableCell className={classes.tableHeaderCell}>
                  Activity
                </TableCell>

                <TableCell className={classes.tableHeaderCell}>
                  Date Time
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  View Activity
                </TableCell>
              </TableRow>
            </TableHead>
            {searchdata.length === 0 ? (
              <>
                <TableBody>
                  {activity
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={row.UserID}
                        className="growInbox"
                        onClick={() => {
                          setSeletedItem(row.UserID);
                          SetmodalisOpen(true);
                          setSelectedActivity(row.Activity);
                          setselecteddatetime(row.Datetime);
                          console.log(row.UserID);
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Grid container>
                            <Grid item lg={2}>
                              <Avatar
                                alt={row.UserID}
                                src="."
                                className={classes.avatar}
                              />
                            </Grid>
                            <Grid item lg={10}>
                              <Typography color="textSecondary" variant="body2">
                                {row.UserID}
                              </Typography>
                              {/* <Typography>{row.Activity}</Typography> */}
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Typography color="textSecondary" variant="body2">
                            {row.Activity}
                          </Typography>
                          <Typography color="textSecondary" variant="body2">
                            {/* {row.company} */}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography color="textSecondary" variant="body2">
                            {row.Datetime}
                          </Typography>
                          <Typography color="textSecondary" variant="body2">
                            {/* {row.company} */}
                          </Typography>
                        </TableCell>

                        {/* <TableCell color="textSecondary" variant="body2">
                          <Typography
                            className={classes.status}
                            style={{
                              backgroundColor:
                                (row.Status === "Active" && "green") ||
                                (row.Status === "Pending" && "blue") ||
                                (row.Status === "Blocked" && "red"),
                            }}
                          >
                            {row.Status}
                          </Typography>
                        </TableCell> */}
                        <TableCell color="textSecondary" variant="body2">
                          <Typography>
                            <Button
                              value={row.UserID}
                              onClick={(e) => {
                                setSeletedItem(row.UserID);
                                SetmodalisOpen(true);
                                setSelectedActivity(row.Activity);
                                setselecteddatetime(row.Datetime);
                                console.log(row.UserID);
                              }}
                            >
                              <AiOutlineEye />
                            </Button>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </>
            ) : (
              <TableBody>
                {searchdata
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      key={row.UserID}
                      className="growInbox"
                      onClick={() => {
                        setSeletedItem(row.UserID);
                        SetmodalisOpen(true);
                        setSelectedActivity(row.Activity);
                        setselecteddatetime(row.Datetime);
                        console.log(row.UserID);
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Grid container>
                          <Grid item lg={2}>
                            <Avatar
                              alt={row.UserID}
                              src="."
                              className={classes.avatar}
                            />
                          </Grid>
                          <Grid item lg={10}>
                            <Typography color="textSecondary" variant="body2">
                              {row.UserID}
                            </Typography>
                            {/* <Typography>{row.Activity}</Typography> */}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary" variant="body2">
                          {row.Activity}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {/* {row.company} */}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography color="textSecondary" variant="body2">
                          {row.Datetime}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {/* {row.company} */}
                        </Typography>
                      </TableCell>

                      {/* <TableCell color="textSecondary" variant="body2">
                          <Typography
                            className={classes.status}
                            style={{
                              backgroundColor:
                                (row.Status === "Active" && "green") ||
                                (row.Status === "Pending" && "blue") ||
                                (row.Status === "Blocked" && "red"),
                            }}
                          >
                            {row.Status}
                          </Typography>
                        </TableCell> */}
                      <TableCell color="textSecondary" variant="body2">
                        <Typography>
                          <Button
                            value={row.UserID}
                            onClick={(e) => {
                              setSeletedItem(row.UserID);
                              SetmodalisOpen(true);
                              setSelectedActivity(row.Activity);
                              setselecteddatetime(row.Datetime);
                              console.log(row.UserID);
                            }}
                          >
                            <AiOutlineEye />
                          </Button>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {searchdata.length === 0 ? (
          <TableFooter style={{ width: "auto" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20]}
              component="div"
              count={activity.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableFooter>
        ) : (
          <TableFooter style={{ width: "auto" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20]}
              component="div"
              count={searchdata.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableFooter>
        )}

        <ReactModal
          style={modalStyle}
          isOpen={modalisOpen}
          onRequestClose={() => SetmodalisOpen(false)}
        >
          <Button style={closebtn} onClick={() => SetmodalisOpen(false)}>
            <FaTimes />
          </Button>
          {/* <h3>{msgfromserver}</h3> */}
          <h6>{selectedItem}</h6>
          <h3>{selectedActivity}</h3>
          <h3>at {selectedDattime}</h3>

          {/* <div onChange={onChangeValue}>
            <input type="radio" value="Admin" name="userType" />
            Admin
            <input type="radio" value="User" name="userType" /> User
          </div>
          <Button> Deactivate</Button>
          <Button onClick={handleupdateSelected}> Update</Button> */}
        </ReactModal>
      </div>
   
    </div>
  );
}

export default UserActivities;
