import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const userSlice = createSlice({
  name: "user",
  initialState: {
    username: "",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    isAdmin: false,
    isLogin: false,
  },
  reducers: {
    // Reducer comes here
  },
  userLogout: (state) => {
    state.username = "";
    state.islogin = false;

    // Extra reducer comes here
  },
  userNameLogin: (state, action) => {
    state.username = action.payload;
    state.isLogin = true;
    //  state.userName += action.payload;
  },
});
export const { userLogout, userNameLogin, reducers } = userSlice.actions;

export const selectUserName = (state) => state.username;
export default userSlice.reducer;
// export const userSelector = (state) => state.user;
