export const MenuItems = [
  {
    title: "Home",
    url: "/Home",
    cName: "nav-links",
  },
  {
    title: "Certified Advantage",
    url: "/CertifiedAdvantage",
    cName: "nav-links",
  },
  {
    title: "Registration",
    url: "/RegistrationProcess",
    cName: "nav-links",
  },

  // {
  //   title: "Store",
  //   url: "/store",
  //   cName: "nav-links",
  // },

  {
    title: "News",
    url: "/News",
    cName: "nav-links",
  },

  {
    title: "Events",
    url: "/Events",
    cName: "nav-links",
  },

  // {
  //   title: "Resources",
  //   url: "/Resources",
  //   cName: "nav-links",
  // },

  // {
  //   title: "Support",
  //   url: "/Support",
  //   cName: "nav-links",
  // },

  {
    title: "Contact Us",
    url: "/ContactUs",
    cName: "nav-links",
  },
  {
    title: "About us",
    url: "/About",
    cName: "nav-links",
  },

  {
    title: "Login",
    url: "/LogIn",
    cName: "nav-links-mobile",
  },
];
