import { Avatar, Button, Grid, Link, Paper } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { CloseOutlined } from "@material-ui/icons";
import LockIcon from "@material-ui/icons/Lock";
import axios from "axios";
import React, { useState } from "react";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import ReactModal from "react-modal";
import { NavLink, useHistory } from "react-router-dom";
import Footer from "../components/Footer";
import ReCAPTCHA from "react-google-recaptcha";

import "antd/dist/antd.css";
import "./index.css";
import { notification } from "antd";
import Changepassword from "./Changepassword";
import { useDispatch, useSelector } from "react-redux";

import {
  decrement,
  increment,
  incrementByAmount,
  resettheValue,
} from "../redux/Counter";
import { userNameLogin, userLogout } from "../redux/userSlice";

const LogIn = (props) => {
  const dispatch = useDispatch();

  console.log("this is the login props: ", props);
  const paperStyle = {
    padding: 20,
    width: " 478px",
    height: "640px",
    margin: "150px auto",
    borderRadius: "30px",
  };
  const closebtn = {
    padding: 0,
    margin: 0,
    position: "absolute",
    right: 0,
    top: 0,
  };

  //for recaptcha
  const [isverfiedCaptcha, setisverfiedCaptcha] = useState(false);
  function onChange(value) {
    console.log("Captcha value:", value);
    setisverfiedCaptcha(true);
  }

  const modalStyle = {
    content: {
      backgroundColor: "#FFFF",
      padding: "50px",
      zIndex: 1000,
      width: "70%",
      margin: "auto auto",
      height: "15rem",
      maxWidth: "30rem",
      opacity: 0.9,
      boxShadow: "rgba(0,0,0,0.5) 0px 0px 24px",
      borderRadius: "30px",
    },
  };

  const avatarStyle = { backgroundColor: "#defed" };
  const btnstyle = {
    margin: "8px 0px",
    width: "162px",
    height: "51px",
    fontFamily: "'Archivo'",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: " 22px",
    backgroundColor: "#F7931E",
    color: "#fff",
    borderRadius: "30px",
  };
  const textStyle = {
    margin: "8px 0px",
    width: "338px",
    height: "64px ",
    fontFamily: "'Archivo'",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: " 22px",
  };
  const history = useHistory();
  console.log(" form login page : ", props.UserImageUrl);

  const [usernameLogin, setUsernameLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  const [emailLogin, setEmailLogin] = useState("");

  const clientId =
    "861968970916-r0gnonpbo5cfuhh8mmn6gqb5c8htg3jb.apps.googleusercontent.com";

  const [showloginButton, setShowloginButton] = useState(true);
  const [showlogoutButton, setShowlogoutButton] = useState(false);
  const [pangalan, setPangalan] = useState();
  const [imahe, setImahe] = useState();
  const [modalisOpen, SetmodalisOpen] = useState(false);
  const [msgfromserver, setmsgfromserver] = useState("");
  const [whatdoyouwant, setWhatdoyouwant] = useState("");
  const [clickSeepassword, setclickSeepassword] = useState(false);

  const [valuePersonalInfo, setvaluePersonalInfo] = useState({
    Lastname: "",
    Firstname: "",
    Middlename: "",
    Email: "",
    Cellphone: "",
    HouseNumber: "",
    Barangay: "",
    City: "",
    ProvinceZipCode: "",
    ZipCode: "",
    RStreetNumber: "",
    RBarangay: "",
    RCity: "",
    RProvince: "",
    RZipCode: "",
    REmergencyPhone: "",
    RHousNumber: "",
    DOB: "",
    StreetNumber: "",
    YearsAtCurrentAddress: "",
  });

  const [valueFreelancer, setvalueFreelancer] = useState({
    Firstname: "",
    Lastname: "",
    Middlename: "",
    Skills: "",
    Experience: "",
    HourlyRate: "",
    Internetspeed: "",
    ProfilePicture: "",
  });
  function LoadData(usernameParams) {
    let formData = new FormData();

    formData.append("UserID", usernameParams);

    //this axios api will display personal info

    axios({
      method: "post",
      url: "https://gisupport.org/backend/viewPersonalInfoForm.php",

      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        console.log("this is from server response Personal Info: ", response);

        if (response.data[0].Record === "1") {
          setvaluePersonalInfo({
            ...valuePersonalInfo,
            Lastname: response.data[0].Lastname,
            Firstname: response.data[0].Firstname,
            Middlename: response.data[0].Middlename,
            Email: response.data[0].Email,
            Cellphone: response.data[0].cellphone,
            HouseNumber: response.data[0].HouseNumber,
            Barangay: response.data[0].Barangay,
            City: response.data[0].City,
            ProvinceZipCode: response.data[0].ProvinceZipCode,
            ZipCode: response.data[0].ZipCode,
            RStreetNumber: response.data[0].RStreetNumber,
            RBarangay: response.data[0].RBarangay,
            RCity: response.data[0].RCity,
            RProvince: response.data[0].RProvince,
            RZipCode: response.data[0].RZipCode,
            REmergencyPhone: response.data[0].REmergencyPhone,
            RHousNumber: response.data[0].RHousNumber,
            DOB: response.data[0].DOB,
            StreetNumber: response.data[0].StreetNumber,
            YearsAtCurrentAddress: response.data[0].YearsAtCurrentAddress,
          });

          console.log("DOBE HERE", response.data[0].DOB);
        } else if (
          response.data[0].Record === "0" ||
          response.data.Record === "0"
        ) {
          alert("no record found");
        }
      })
      .catch(function (response) {
        //handle error
        alert("This profile not yet submited the Personal Information");
      });

    //end personal info code

    axios({
      method: "post",
      url: "https://gisupport.org/backend/ViewProfileFreelancers.php",

      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        if (response.data[0].Record === "1") {
          setvalueFreelancer({
            ...valueFreelancer,
            Lastname: response.data[0].Lastname,
            Firstname: response.data[0].Firstname,
            Middlename: response.data[0].Middlename,
            Skills: response.data[0].Skills,
            Experience: response.data[0].Experience,

            HourlyRate: response.data[0].HourlyRate,

            Iternetspeed: response.data[0].Internetspeed,
            ProfilePicture:
              "http://gisupport.org/uploads/" + response.data[0].ProfilePicture,
          });
          localStorage.setItem(
            "imageProfileUrl",
            "http://gisupport.org/uploads/" + response.data[0].ProfilePicture
          );
          dispatch(usernameLogin(usernameLogin));
        } else {
          localStorage.setItem("isProfileExist", false);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }

  const onLoginSuccess = (res) => {
    console.log("Login Success:", res.profileObj);

    console.log(" image url nya:", res.profileObj.imageUrl);
    console.log("name nya: ", res.profileObj.givenName);
    setImahe(res.profileObj.imageUrl);
    setPangalan(res.profileObj.givenName);

    // localStorage.setItem("islogin", "true");
    // localStorage.setItem("yourusername", usernameLogin);
    // history.push("/Activation");

    setShowloginButton(false);
    setShowlogoutButton(true);
    // history.push("/Activation");

    localStorage.setItem("islogin", "true");
    localStorage.setItem("yourusername", pangalan);

    localStorage.setItem("isAdmin", "false");
    localStorage.setItem("profilepicfromgoogle", res.profileObj.imageUrl);
    localStorage.setItem("GoogleLogin", true);
    localStorage.setItem("isAdmin", "false");
    setmsgfromserver("WELCOME " + usernameLogin);
    SetmodalisOpen(true);
    // props.handleLogin(res);
    // props.UserImageUrl = res.profileObj.imageUrl;

    // history.push("/Profile");

    setmsgfromserver("WELCOME " + res.profileObj.givenName);
    SetmodalisOpen(true);

    // history.push("/Profile");
  };

  const someObject = {
    foo: "This",
    bar: "works!",
  };

  const onLoginFailure = (res) => {
    console.log("Login Failed:", res);
  };

  const onSignoutSuccess = () => {
    alert("You have been logged out successfully");
    console.clear();
    setShowloginButton(true);
    setShowlogoutButton(false);
    setPangalan(null);
    setImahe(null);
  };

  // function getProfileData{

  // };

  function submitForm(event) {
    event.preventDefault();

    let formData = new FormData();
    formData.append("username", usernameLogin);
    formData.append("email", usernameLogin);
    formData.append("pass", passwordLogin);

    axios({
      method: "post",
      url: "https://gisupport.org/backend/checklogin.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        // console.log(response);
        const reply = response.data;
        console.log("reply from auto login page", reply);
        if (reply.Auth === "Valid") {
          if (reply.UserType === "Admin") {
            console.log("admin reply from server", reply);
            localStorage.setItem("islogin", "true");
            localStorage.setItem("yourusername", usernameLogin);
            localStorage.setItem("isAdmin", "true");
            setmsgfromserver("WELCOME ADMIN " + usernameLogin);

            // SetmodalisOpen(true);

            //this is the last comment
            // history.push("/Admindashboard");
            LoadData(usernameLogin);
            // dispatch(userNameLogin(usernameLogin));

            history.push("/firstlogin");

            props.handleLogin(reply);
            console.log("you are admin ");

            // try no location.reload
            // window.location.reload();
          } else {
            console.log("you are not an Admin");

            localStorage.setItem("islogin", "true");
            localStorage.setItem("yourusername", usernameLogin);
            localStorage.setItem("isAdmin", "false");
            setmsgfromserver("WELCOME " + usernameLogin);
            // SetmodalisOpen(true);

            //start comment here
            props.handleLogin(reply);

            //this is the last comment
            // history.push("/Profile");
            LoadData(usernameLogin);
            history.push("/firstlogin");

            //try no location reload
          }
          // history.go(0);
          //   window.location.reload();
        } else if (reply.Auth === "Changepass") {
          history.push("/Changepassword");

          if (reply.UserType === "Admin") {
            localStorage.setItem("islogin", "true");
            localStorage.setItem("yourusername", usernameLogin);
            localStorage.setItem("isAdmin", "true");
            setmsgfromserver("WELCOME ADMIN " + usernameLogin);

            // SetmodalisOpen(true);

            props.handleLogin(reply);

            // try no location.reload
            // window.location.reload();
          } else {
            console.log("you are not an Admin");

            localStorage.setItem("islogin", "true");
            localStorage.setItem("yourusername", usernameLogin);
            localStorage.setItem("isAdmin", "false");
            setmsgfromserver("WELCOME " + usernameLogin);
            // SetmodalisOpen(true);

            //start coment here
            props.handleLogin(reply);

            //try no location reload
          }
        } else {
          setmsgfromserver("INVALID CREDENTIALS");
          openNotification();
          SetmodalisOpen(true);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    //notification codes

    const openNotification = () => {
      notification.open({
        message: "Message from server",
        description: "sorry your credential didn't match to our database",
        className: "custom-class",
        placement: "bottomRight",
        duration: 5,
        style: {
          width: 600,
        },
      });
    };
  }

  return (
    <>
      <Grid align="center">
        <form>
          <Paper elevation={10} style={paperStyle}>
            <Avatar style={avatarStyle}>
              {" "}
              <LockIcon />
            </Avatar>
            <h4
              style={{
                color: "#7A00E4",
                Font: "Archivo",
                Weight: "700",
                Size: "25px",
                LineHeight: "27.2px",
              }}
            >
              Sign in
            </h4>
            {/* <h3>this is the data: {props.loggenInStatus}</h3> */}

            <TextField
              style={textStyle}
              label="Email Address"
              placeholder="Enter your email address "
              onChange={(e) => {
                setUsernameLogin(e.target.value);
              }}
              fullWidth
            />
            <TextField
              style={textStyle}
              label="Password"
              type={!clickSeepassword ? "password" : "text"}
              autoComplete
              required
              onChange={(e) => {
                setPasswordLogin(e.target.value);
              }}
              fullWidth
            />

            <span
              style={{
                position: "absolute",
                marginTop: "2rem",
                marginLeft: "-2rem",
              }}
              className={
                !clickSeepassword ? "fa fa-fw fa-eye " : "fas fa-eye-slash"
              }
              onMouseEnter={() => {
                setclickSeepassword((clickSeepassword) => !clickSeepassword);
              }}
              onClick={() => {
                setclickSeepassword((clickSeepassword) => !clickSeepassword);
              }}
            ></span>

            {/* <FormControlLabel
              control={<Checkbox name="checkedB" color="primary" />}
              label="Remember me"
            /> */}
            <ReCAPTCHA
              sitekey="6LePCsgfAAAAAIbtwV6H-hGS0RFeZ9uyZNetlmQZ"
              onChange={onChange}
              style={{ width: "338px", height: "64px", margin: "3rem auto" }}
            />

            <Button
              type="submit"
              color="primary"
              fontSize="small"
              variant="contained"
              onClick={submitForm}
              disabled={!isverfiedCaptcha}
              fullWidth
              style={btnstyle}
            >
              Sign in
            </Button>

            <Typography>
              <NavLink to="/ForgotPassword">Forgot password?</NavLink>
            </Typography>
            <Typography>
              Do you have an account?
              <NavLink to="/sign-up">Sign up</NavLink>
            </Typography>

            {/* <img src={imahe} alt={imahe} width="250px"></img>
        <p>{pangalan} </p> */}
            <div style={{ display: "none" }}>
              {showloginButton ? (
                <GoogleLogin
                  clientId={clientId}
                  buttonText="Sign In"
                  onSuccess={onLoginSuccess}
                  onFailure={onLoginFailure}
                  cookiePolicy={"single_host_origin"}
                  isSignedIn={true}
                />
              ) : null}

              {showlogoutButton ? (
                <GoogleLogout
                  clientId={clientId}
                  buttonText="Sign Out"
                  onLogoutSuccess={onSignoutSuccess}
                ></GoogleLogout>
              ) : null}
            </div>
          </Paper>
        </form>
      </Grid>
      <ReactModal
        style={modalStyle}
        isOpen={modalisOpen}
        onRequestClose={() => SetmodalisOpen(false)}
      >
        <Button style={closebtn} onClick={() => SetmodalisOpen(false)}>
          {/* <FaTimes /> */}
          <CloseOutlined />
        </Button>
        <h3>{msgfromserver}</h3>
        <h6>Don't have an account? </h6>
        <NavLink to="/sign-up">Sign up</NavLink>
        <br />
        <NavLink to="/ForgotPassword">Forgot Password</NavLink>
      </ReactModal>
      <Footer />
    </>
  );
};

export default LogIn;
