// import React, { useEffect, useState } from "react";
// // import loader from "super-react-loader";

// const PageLoader = () => {
//   const [isloading, setisloading] = useState(true);
//   loader.setPresetLoader({
//     preset: "chase", // loader preset name
//     size: "8rem", // loader size
//     color: "#1890ff", // loader color
//     bg: "#e9ecef", // background property
//   });
//   useEffect(() => {
//     setTimeout(() => {
//       setisloading(false);
//     }, 2500);
//   });

//   return <>{isloading ? loader.show() : loader.hide()}</>;
// };

// export default PageLoader;
import React from "react";

import "./PageLoader.css";

const PageLoader = () => {
  return (
    <div
      className="container-fluid d-flex mx-auto my-auto text-center h-100 w-100 justify-content-center align-items-center"
      style={{ marginTop: "10rem" }}
    >
      <div classname="row d-flex h-100 mx-auto my-auto justify-content-center align-items-center">
        <div className="pageLoader position-absolute mx-auto my-auto"></div>
      </div>
    </div>
  );
};

export default PageLoader;
