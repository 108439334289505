import React, {
  Component,
  props,
  useState,
  lazy,
  useEffect,
  Suspense,
} from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages";
import Homes from "./pages";
// import RegistrationProcess from "./pages/RegistrationProcess";
// import Viewfreelancers from "./pages/Viewfreelancers";
// import Hirefreelancer from "./pages/Hirefreelancer";
// import Howitworks from "./pages/Howitworks";
// import AdminDashboardMonitor from "./pages/AdminDashboardMonitor";
// import SignUp from "./pages/signup";
// import SignIn from "./pages/Signin";
import Container from "@material-ui/core/Container";
import { Button } from "react-bootstrap";
// import Activation from "./pages/Activation";
import LogIn from "./pages/Login";
// import Admindashboard from "./pages/Admindashboard";
// import AddFreelancers from "./components/AddFreelancers";
// import Termsandconditions from "./pages/Termsandconditions";
// import Profile from "./pages/Profile";
// import Profile2 from "./pages/Profile2";
// import Gisclock from "./pages/Gisclock";
// import About from "./pages/About";
// import LandingPageOnceRegistered from "./pages/LandingPageOnceRegistered";
// import CertifiedAdvantage from "./pages/CertifiedAdvantage";
// import GetCertified from "./pages/GetCertified";
// import Certificationrequirements from "./pages/Certificationrequirements";
// import FreelancerQuestionnaire from "./pages/FreelancerQuestionnaire";
// import Tellusaboutyourself from "./pages/Tellusaboutyourself";
// import Lesson1 from "./pages/Lesson1";
import HomeNew from "./pages/HomeNew";
// import ViewProfile from "./components/ViewProfile";
// import ViewIndividualProfile from "./components/ViewIndividualProfile";
// import Messages from "./pages/Messages";
// import Forgotpassword from "./pages/Forgotpassword";
// import Changepassword from "./pages/Changepassword";
// import RegistrationProcess2 from "./pages/RegistrationProcess2";
// import PersonalInformationForm from "./pages/PersonalInformationForm";
// import ExperienceForm from "./pages/ExperienceForm";
import Users from "./components/Users";
// import Hirings from "./components/Hirings";
// import AdminViewfreelancers from "./components/AdminViewfreelancers";
// import UserActivities from "./components/UserActivities";
// import AdminInvoices from "./components/AdminInvoices";
// import InvoiceBuilderTools from "./pages/InvoiceBuilderTools";
// import Store from "./pages/Store";
import Sidebar from "./components/Sidebar";
// import ContactUs from "./pages/ContactUs";
// import WorkspaceInfoForm from "./pages/WorkspaceInfoForm";
// import EquipmentInfoForm from "./pages/EquipmentInfoForm";
// import InvoicBuilder from "./pages/InvoicBuilder";
// import InvoiceUploadPage from "./pages/InvoiceUploadPage";
// import Invitation from "./pages/Invitation";
// import FirstLogin from "./pages/FirstLogin";
// import FreelancerTemplates from "./pages/FreelancerTemplates";
// import InvoiceHistory from "./components/InvoiceHistory";
import Landingpage from "./pages/Landingpage";
import LiveDemo from "./pages/LiveDemo";
// import Template1 from "./Templates/1/Template1";
// import Template2 from "./Templates/2/Template2";
// import Template3 from "./Templates/3/Template3";
// import Template4 from "./Templates/4/Template4";
// import Template5 from "./Templates/5/Template5";
import PureCounter from "@srexi/purecounterjs";
import PageLoader from "./components/PageLoader";
import Inbox from "./components/Inbox";
import Sent from "./components/Sent";
import CreateMessage from "./components/CreateMessage";
import SidebarMessages from "./components/SidebarMessages";
import ErrorSearch from "./pages/ErrorSearch";
import UserActivities from "./components/UserActivities";
// import Getstarted from "./pages/Getstarted";
// import Webpages from "./components/Webpages";
// import Uploadfirebase from "./pages/Uploadfirebase";

// import { io } from "socket.io-client";
// import AdminDashboardv2 from "./components/AdminDashboardv2/AdminDashboardv2";
// import AddProduct from "./components/ManageStore/AddProduct";
// import ViewAllProducts from "./components/ManageStore/ViewAllProducts";
// import ProductReviews from "./pages/ProductReviews";
// import PageLoader from "./components/PageLoader";
const pure = new PureCounter();

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      profileLink: "/Profile",
      loggenInStatus: "NOT_LOGGEN_IN USER",
      user: "User",
      UserID: "UserID",
      IsAdmin: "false",
      UserType: "",
      UserImageUrl: "ImageUrl.com",
      isloading: false,
      socket: null,
      isCertified: false,
    };
    this.handleLogin = this.handleLogin.bind(this);
  }
  handleLogin(data) {
    this.setState({
      // profileLink: newprofilelink,
      user: data.username,
      UserType: data.UserType,
      UserID: data.UserID,
      FirstName: "",
      LastName: "",
      Status: "Active",
      isloading: true,
    });
  }
  onChangeLinkName(newuserName, newprofilelink) {
    this.setState({
      profileLink: newprofilelink,
      user: newuserName,
    });
  }

  //     componentDidMount(()=>{
  //    setTimeout(() => {
  //    this.setState({
  //       isloading: false,
  //     });
  //    }, 2500);
  // })
  // componentDidMount() {
  //   setTimeout(() => {
  //     this.setState({
  //       isloading: false,
  //     });
  //   }, 2500);
  // }

  // componentDidMount() {
  //   this.timer = setInterval(
  //     () => this.setState((prevState) => ({ isloading: !prevState.isloading })),
  //     2500
  //   );
  // }
  // componentWillUnmount() {
  //   clearInterval(this.timer);
  // }

  // componentDidMount() {
  //   // Changing the state after 2 sec
  //   // from the time when the component
  //   // is rendered
  //   setTimeout(() => {
  //     this.setState({ isloading: false });
  //   }, 2000);
  // }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({ isloading: false });
    }, 1500);
  }

  //this is for socket io

  // componentDidMount() {
  //   // const socket = io("https://gisupport.org");
  //   const socket = io("http://localhost:5000");
  //   console.log(
  //     socket.on("firstEvent", (msg) => {
  //       console.log(socket, msg);
  //     })
  //   );
  // }

  AdminDashboardv2 = lazy(() =>
    import("./components/AdminDashboardv2/AdminDashboardv2")
  );

  ProductReviews = lazy(() => import("./pages/ProductReviews"));

  AddProduct = lazy(() => import("./components/ManageStore/AddProduct"));

  ViewAllProducts = lazy(() =>
    import("./components/ManageStore/ViewAllProducts")
  );
  ViewAllScores = lazy(() => import("./components/Test/AdminViewScores"));

  Uploadfirebase = lazy(() => import("./pages/Uploadfirebase"));
  Webpages = lazy(() => import("./components/Webpages"));
  Education = lazy(() => import("./components/Education"));
  Skills = lazy(() => import("./components/Skills"));

  RegistrationProcess = lazy(() => import("./pages/RegistrationProcess"));

  Viewfreelancers = lazy(() => import("./pages/Viewfreelancers"));

  Hirefreelancer = lazy(() => import("./pages/Hirefreelancer"));

  Howitworks = lazy(() => import("./pages/Howitworks"));
  AdminDashboardMonitor = lazy(() => import("./pages/AdminDashboardMonitor"));
  SignUp = lazy(() => import("./pages/signup"));
  SignIn = lazy(() => import("./pages/Signin"));

  Activation = lazy(() => import("./pages/Activation"));
  // LogIn = lazy(() => import("./pages/Login"));
  Admindashboard = lazy(() => import("./pages/Admindashboard"));
  AddFreelancers = lazy(() => import("./components/AddFreelancers"));
  PdfViewFreelancer = lazy(() => import("./components/PdfViewFreelancer"));
  ViewAllGetstarted = lazy(() => import("./components/ViewallGetstarted"));
  Termsandconditions = lazy(() => import("./pages/Termsandconditions"));
  Profile = lazy(() => import("./pages/Profile"));
  Profile2 = lazy(() => import("./pages/Profile2"));
  Gisclock = lazy(() => import("./pages/Gisclock"));
  About = lazy(() => import("./pages/About"));
  LandingPageOnceRegistered = lazy(() =>
    import("./pages/LandingPageOnceRegistered")
  );
  CertifiedAdvantage = lazy(() => import("./pages/CertifiedAdvantage"));
  GetCertified = lazy(() => import("./pages/GetCertified"));
  Certificationrequirements = lazy(() =>
    import("./pages/Certificationrequirements")
  );
  FreelancerQuestionnaire = lazy(() =>
    import("./pages/FreelancerQuestionnaire")
  );

  Tellusaboutyourself = lazy(() => import("./pages/Tellusaboutyourself"));
  Lesson1 = lazy(() => import("./pages/Lesson1"));
  HomeNew = lazy(() => import("./pages/HomeNew"));
  ViewProfile = lazy(() => import("./components/ViewProfile"));
  ViewIndividualProfile = lazy(() =>
    import("./components/ViewIndividualProfile")
  );
  Messages = lazy(() => import("./pages/Messages"));
  Forgotpassword = lazy(() => import("./pages/Forgotpassword"));
  Changepassword = lazy(() => import("./pages/Changepassword"));

  RegistrationProcess2 = lazy(() => import("./pages/RegistrationProcess2"));

  PersonalInformationForm = lazy(() =>
    import("./pages/PersonalInformationForm")
  );

  ExperienceForm = lazy(() => import("./pages/ExperienceForm"));
  // Users = lazy(() => import("./components/Users"));
  Hirings = lazy(() => import("./components/Hirings"));

  AdminViewfreelancers = lazy(() =>
    import("./components/AdminViewfreelancers")
  );
  UserActivities = lazy(() => import("./components/UserActivities"));

  AdminInvoices = lazy(() => import("./components/AdminInvoices"));
  InvoiceBuilderTools = lazy(() => import("./pages/InvoiceBuilderTools"));
  Store = lazy(() => import("./pages/Store"));

  // Sidebar = lazy(() => import("./components/Sidebar"));
  ContactUs = lazy(() => import("./pages/ContactUs"));

  WorkspaceInfoForm = lazy(() => import("./pages/WorkspaceInfoForm"));
  EquipmentInfoForm = lazy(() => import("./pages/EquipmentInfoForm"));
  InvoicBuilder = lazy(() => import("./pages/InvoicBuilder"));
  InvoiceUploadPage = lazy(() => import("./pages/InvoiceUploadPage"));
  Invitation = lazy(() => import("./pages/Invitation"));
  FirstLogin = lazy(() => import("./pages/FirstLogin"));
  FreelancerTemplates = lazy(() => import("./pages/FreelancerTemplates"));

  InvoiceHistory = lazy(() => import("./components/InvoiceHistory"));

  // Landingpage = lazy(() => import("./pages/Landingpage"));
  // LiveDemo = lazy(() => import("./pages/LiveDemo"));
  Template1 = lazy(() => import("./Templates/1/Template1"));
  Template2 = lazy(() => import("./Templates/2/Template2"));
  Template3 = lazy(() => import("./Templates/3/Template3"));
  Template4 = lazy(() => import("./Templates/4/Template4"));
  Template5 = lazy(() => import("./Templates/5/Template5"));
  Template7 = lazy(() => import("./Templates/7/Template7"));
  // Template8 = lazy(() => import("./Templates/8/Template8"));

  PureCounter = lazy(() => import("@srexi/purecounterjs"));
  Getstarted = lazy(() => import("./pages/Getstarted"));
  Basictesting = lazy(() => import("./components/Test/Basictesting"));
  Word = lazy(() => import("./components/Test/Word"));
  Excel = lazy(() => import("./components/Test/Excel"));
  Outlook = lazy(() => import("./components/Test/Outlook"));
  Powerpoint = lazy(() => import("./components/Test/Powerpoint"));
  Menupage = lazy(() => import("./components/Test/Menupage"));
  render() {
    return (
      <>
        <Router>
          <Navbar
            {...props}
            user={this.state.user}
            propsFirstName={this.state.Firstname}
            propsLastName={this.state.LastName}
            handleLogin={this.handleLogin}
            loggenInStatus={this.state.loggenInStatus}
          />
          <Suspense
            fallback={
              <div style={{ marginTop: "8rem" }}>
                <PageLoader />
              </div>
            }
          >
            <Switch>
              <Route path="/Menupage" component={this.Menupage}></Route>
              <Route path="/Outlook" component={this.Outlook}></Route>
              <Route path="/Powerpoint" component={this.Powerpoint}></Route>
              <Route path="/Excel" component={this.Excel}></Route>
              <Route path="/Word" component={this.Word}></Route>
              <Route path="/Basictesting" component={this.Basictesting}></Route>
              <Route path="/Education" component={this.Education}></Route>
              <Route path="/Skills" component={this.Skills}></Route>
              <Route path="/Inbox">
                <div className="container-fluid">
                  <div className="row" style={{ marginTop: "1rem" }}>
                    <div className="col-lg-2">
                      <SidebarMessages />
                    </div>
                    <div className="col-lg-9" style={{ marginTop: "9rem" }}>
                      <Inbox />
                    </div>
                  </div>
                </div>
              </Route>
              <Route path="/Sent">
                <div className="container-fluid">
                  <div className="row" style={{ marginTop: "1rem" }}>
                    <div className="col-lg-2">
                      <SidebarMessages />
                    </div>
                    <div className="col-lg-9" style={{ marginTop: "9rem" }}>
                      <Sent />
                    </div>
                  </div>
                </div>
              </Route>
              <Route path="/CreateMessage">
                <div className="container-fluid">
                  <div className="row" style={{ marginTop: "1rem" }}>
                    <div className="col-lg-2">
                      <SidebarMessages />
                    </div>
                    <div className="col-lg-9" style={{ marginTop: "9rem" }}>
                      <CreateMessage />
                    </div>
                  </div>
                </div>
              </Route>
              <Route
                path="/ProductReviews/:ProductID"
                component={this.ProductReviews}
              />
              <Route path="/ViewAllScores" component={this.ViewAllScores} />
              <Route path="/ViewAllProducts" component={this.ViewAllProducts} />
              <Route path="/Addproduct" component={this.AddProduct} />
              <Route path="/Profile2" component={this.Profile2} />
              <Route path="/Upload" component={this.Uploadfirebase} />
              <Route
                path="/Admindashboardv2"
                component={this.AdminDashboardv2}
              />
              <Route
                path="/PdfViewFreelancer/:UserID"
                component={this.PdfViewFreelancer}
              />
              <Route
                path="/ViewAllGetstarted"
                component={this.ViewAllGetstarted}
              />
              <Route path="/Webpages/:id" component={this.Webpages} />
              <Route path="/Getstarted" component={this.Getstarted} />
              <Route path="/FirstLogin" component={this.FirstLogin} />
              <Route path="/Landingpage" component={<Landingpage />} />
              <Route path="/Template1" component={this.Template1} />
              <Route path="/Template2" component={this.Template2} />
              <Route path="/Template3" component={this.Template3} />
              <Route path="/Template4" component={this.Template4} />
              <Route path="/Template5" component={this.Template5} />
              <Route path="/Template7" component={this.Template7} />
              {/* <Route path="/Template8" component={this.Template8} /> */}
              <Route path="/Store" component={this.Store} />
              <Route
                path="/Home"
                render={(props) => (
                  <Home {...props} loggenInStatus={this.state.loggenInStatus} />
                )}
              />
              <Route
                path="/freelancerstemplate"
                component={this.FreelancerTemplates}
              />
              <Route path="/ContactUs" component={this.ContactUs} />
              <Route
                path="/RegistrationProcess2"
                component={this.RegistrationProcess2}
              />
              <Route
                path="/LiveDemo"
                render={(props) => <LiveDemo {...props} />}
              ></Route>
              <Route
                path="/PersonalInformationForm"
                component={this.PersonalInformationForm}
              />
              <Route path="/InvoicBuilder" component={this.InvoicBuilder} />
              <Route
                path="/Certificationrequirements"
                component={this.Certificationrequirements}
              />
              <Route
                path="/InvoiceUploadPage"
                component={this.InvoiceUploadPage}
              />
              <Route path="/Viewfreelancers" component={this.Viewfreelancers} />
              <Route path="/Hirefreelancer" component={this.Hirefreelancer} />
              <Route path="/Howitworks" component={this.Howitworks} />
              <Route path="/sign-up" component={this.SignUp} />
              <Route path="/SignIn" component={this.SignIn} />
              <Route path="/Activation" component={this.Activation} />
              <Route path="/Admindashboard" component={this.Admindashboard} />
              <Route path="/Addfreelancers" component={this.AddFreelancers} />
              <Route
                path="/termsandconditions"
                component={this.Termsandconditions}
              />
              <Route path="/Profile" component={this.Profile} />
              <Route path="/About" component={this.About} />
              <Route path="/Invitation" component={this.Invitation} />
              <Route
                path="/RegistrationProcess"
                component={this.RegistrationProcess}
              />
              <Route path="/HomeNew" component={this.HomeNew} />
              <Route path="/ViewProfile/:id" component={this.ViewProfile} />
              <Route path="/Messages" component={this.Messages} />
              <Route path="/ForgotPassword" component={this.Forgotpassword} />
              <Route path="/Changepassword" component={this.Changepassword} />
              <Route path="/ExperienceForm" component={this.ExperienceForm} />
              <Route
                path="/Workspaceinfoform"
                component={this.WorkspaceInfoForm}
              />
              <Route
                path="/AdminDashboardMonitor"
                component={this.AdminDashboardMonitor}
              />
              <Route
                path="/PersonalInfoForm"
                component={this.PersonalInformationForm}
              />
              <Route
                path="/InvoiceBuilderTools"
                component={this.InvoiceBuilderTools}
              />
              <Route
                path="/ViewIndividualProfile"
                component={this.ViewIndividualProfile}
              />
              {/* <Route path="/WorkspaceInfoForm" component={WorkspaceInfoForm} */}
              <Route path="/Users">
                <div className="d-flex mt-4" style={{ marginTop: "4rem" }}>
                  <Sidebar />

                  <Users />
                </div>
              </Route>
              <Route path="/AdminInvoices">
                <div className="d-flex mt-4" style={{ marginTop: "4rem" }}>
                  {this.Sidebar}
                  {this.AdminInvoices}
                </div>
              </Route>
              <Route path="/InvoiceHistory">{this.InvoiceHistory}</Route>
              <Route path="/AdminFreelancers">
                <div className="d-flex mt-4" style={{ marginTop: "4rem" }}>
                  {this.Sidebar}
                  {this.AdminViewfreelancers}
                </div>
              </Route>
              <Route path="/Hirings">
                <div className="d-flex mt-4" style={{ marginTop: "4rem" }}>
                  {this.Sidebar}
                  {this.Hirings}
                </div>
              </Route>
              <Route path="/UserActivities">
                <div className="d-flex mt-4" style={{ marginTop: "4rem" }}>
                <Sidebar/>
                 <UserActivities/>
                </div>
              </Route>{" "}
              <Route path="/ViewProfile/:id" component={this.ViewProfile} />
              <Route
                path="/LandingPageOnceRegistered"
                component={this.LandingPageOnceRegistered}
              />
              <Route path="/Lesson1" component={this.Lesson1} />
              <Route
                path="/FreelancerQuestionnaire"
                component={this.FreelancerQuestionnaire}
              />
              <Route
                path="/Tellusaboutyourself"
                component={this.Tellusaboutyourself}
              />
              <Route
                path="/CertifiedAdvantage"
                component={this.CertifiedAdvantage}
              />
              <Route path="/GetCertified" component={this.GetCertified} />
              <Route
                path="/WorkspaceInfoForm"
                component={this.WorkspaceInfoForm}
              />
              <Route
                path="/EquipmentInfoForm"
                component={this.EquipmentInfoForm}
              />
              <Route
                path="/LogIn"
                render={(props) => (
                  <LogIn
                    {...props}
                    handleLogin={this.handleLogin}
                    loggenInStatus={this.state.loggenInStatus}
                  />
                )}
              />
              <Route path="/Gisclock" component={this.Gisclock} />
              {/* <Route path="/" component={<Landingpage />} /> */}
              <Route exact path="/" component={Home} />
              <Route path="/*" component={ErrorSearch} />
              <Route path="/Webpages/*" component={ErrorSearch} />
            </Switch>
          </Suspense>
        </Router>
      </>
    );
  }
}
