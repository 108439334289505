import React, { Component, useState, props } from "react";
import { Button } from "../Button";
import { MenuItems } from "./MenuItems";
import "./Navbar.css";
import { NavLink as Link } from "react-router-dom";
import { useHistory as history, Redirect } from "react-router-dom";
import { Avatar, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { Row } from "react-bootstrap";
import axios from "axios";
import { AiFillBell } from "react-icons/ai";
import { ArrowDropDownOutlined } from "@material-ui/icons";

import { Dropdown, Menu, Space } from "antd";
import { io } from "socket.io-client";
import AdminMenu from "./AdminMenu";
import { useDispatch, useSelector } from "react-redux";
import userSlice, {
  selectUserName,
  userNameLogin,
} from "../../redux/userSlice";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      HaveProfile: false,
      profilePic: "",
      clicked: false,
      userID: props.username,
      // userID: localStorage.getItem("yourusername"),
      Lastname: localStorage.getItem("LastName"),
      Firstname: localStorage.getItem("FirstName"),
      adminHover: false,
      // Fullname: useSelector((state) => state.userNameLogin),
      // userName: useSelector(this.selectUserName),
      isCertified: false,
    };
    this.handleSuccessfulAuth = this.handleSuccessfulAuth.bind(this);
    this.handleSendOnlineuser = this.handleSendOnlineuser.bind(this);
    console.log("this is the props: props.user" + props.user);
    console.log(
      "props logenin status: props.loggenInstatus ",
      props.loggenInStatus
    );
    console.log("this is all the props from navbar: props", props);
    console.log(
      "this is react reduxt toolkit value of username: ",
      this.userName
    );
  }

  handleSuccessfulAuth(data) {
    this.props.handleLogin(data);
    this.props.history.push("/Home");
    // const [fromdata, setfromdata] = useState(data);
  }

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });

    //  state = { clicked: false };
  };
  AdminHover = () => {
    this.setState({ adminHover: !this.state.adminHover });
  };

  handleSendOnlineuser = () => {
    const socket = io("http://localhost:5000");
    if (this.state.userID !== null) {
      socket.emit("login", { userId: this.state.userID });
    }
  };

  componentDidMount() {
    // const [HaveProfile, sethavprofile] = useState(false);
    // const [profilePic, setprofilePic] = useState();

    //socket IO getting data from server

    // const socket = io("http://localhost:5000");
    // console.log(
    //   socket.on("ConnectedUsers", (onlineUsers) => {
    //     console.log(" this is from server socket", onlineUsers);
    //   })
    // );
    // this.handleSendOnlineuser();

    //end of socket IO

    let formData = new FormData();

    const username = localStorage.getItem("yourusername");

    const urlimage = "http://gisupport.org/uploads/";
    console.log(" ID inside componentdidmount : ", username);

    formData.append("UserID", username);
    console.log("this is the form data", formData);

    //this axios will  display the freelancers Info
    axios({
      method: "post",
      url: "https://gisupport.org/backend/ViewProfileFreelancers.php",

      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then((response) => {
        if (response.data[0].Record === "1") {
          console.log(response.data[0].Firstname);

          this.setState({ HaveProfile: true });
          this.setState({
            profilePic: `http://gisupport.org/uploads/${response.data[0].ProfilePicture}`,
          });
          console.log(
            "this is from axios class component: ",
            response.data[0].ProfilePicture
          );
        } else {
          this.setState({ HaveProfile: false });
        }
      })

      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }
  componentDidUpdate() {
    //document.title = `You clicked ${this.state.count} times`;
    // setUserDidMount(this.props.user);
    // console.log("from didmoun:", userdidmount);
  }

  render() {
    //uesEffect
    // useEffect(() => {
    //   //
    // }, []);
    // const [isLoginFromGoogle, setisLoginFromGoogle] = useState(false);
    // setisLoginFromGoogle(localStorage.getItem("GoogleLogin"));

    const isLogin = localStorage.getItem("islogin") === "true";
    const Admin = localStorage.getItem("isAdmin") === "true";

    const username = localStorage.getItem("yourusername");
    const Firstname = localStorage.getItem("FirstName");
    const lastname = localStorage.getItem("LastName");

    function logout() {
      localStorage.clear();

      window.location.replace("/");
    }

    return (
      <>
        {/* <nav className="NavbarItems" id="navbar4" style={{ zIndex: "1000" }}> */}
        <nav
          className="NavbarItems"
          id="NavbarItems"
          style={{ zIndex: "9998" }}
        >
          <div
            className="nav-main"
            data-aos="slide-down"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <Link to="/Home">
              <img
                src="https://gisupport.org/gislogo.png"
                width="200px"
                alt="Gis logo"
              ></img>
              {/* {this.state.userName} */}
            </Link>
            <div className="menu-icon" onClick={this.handleClick}>
              <i
                className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
              ></i>
            </div>

            {!isLogin ? (
              <div className="nav-links-center">
                <ul
                  className={
                    this.state.clicked ? "nav-menu active" : "nav-menu"
                  }
                >
                  {MenuItems.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link
                          className={item.cName}
                          to={item.url}
                          onClick={this.handleClick}
                        >
                          {item.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>

                {/* <Link to="/LogIn" className="wagtang">
                  <Button variant="contained" color="secondary">
                    Login
                  </Button>
                </Link> */}
              </div>
            ) : (
              <div className="nav-links-center">
                <ul
                  className={
                    this.state.clicked ? "nav-menu active" : "nav-menu"
                  }
                >
                  <li>
                    <Link
                      className="nav-links"
                      to="/About"
                      onClick={this.handleClick}
                    >
                      About us
                    </Link>
                  </li>
                  {this.state.isCertified ? (
                    <li>
                      <Link
                        className="nav-links"
                        to="/Store"
                        onClick={this.handleClick}
                      >
                        Store
                      </Link>
                    </li>
                  ) : null}

                  {/* <li>
                    <Link
                      className="nav-links"
                      to="/newsandevents"
                      onClick={this.handleClick}
                    >
                      News and Events
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      className="nav-links"
                      to="/CertifiedAdvantage"
                      onClick={this.handleClick}
                    >
                      Certified Advantages
                    </Link>
                  </li>
                  {/* <li> */}

                  {Admin ? (
                    <>
                      {/* 
                    this is last code */}

                      <li
                        className="dropdown"
                        onMouseEnter={this.AdminHover}
                        onMouseLeave={this.AdminHover}
                      >
                        <Link to="/Admindashboardv2">
                          <span className="nav-links">Admin Dashboard</span>{" "}
                          <i
                            className={
                              this.state.adminHover
                                ? "bi bi-chevron-down"
                                : "bi bi-chevron-right"
                            }
                          ></i>
                        </Link>
                        <ul>
                          <li>
                            <a href="#">Registration</a>
                          </li>
                          <li>
                            <a href="#">Certification</a>
                          </li>
                          <li>
                            <Link to="/freelancerstemplate">
                              Personal Webpage Developement
                            </Link>
                          </li>
                          <li>
                            <a href="#">Learning and Improvement Center</a>
                          </li>
                        </ul>
                      </li>
                      {/* <li>
                          <a href="#">Drop Down 2</a>
                        </li>
                        <li>
                          <a href="#">Drop Down 3</a>
                        </li>
                        <li>
                          <a href="#">Drop Down 4</a>
                        </li> */}
                    </>
                  ) : null}
                  {/* </li> */}
                  {/* <li>
                    <Link
                      className="nav-links"
                      to="/Hirefreelancer"
                      onClick={this.handleClick}
                    >
                      Hire
                    </Link>
                  </li> */}
                  <li>
                    {/* <Link 
                    className="nav-links"
                    to="/sign-up"
                    onClick={this.handleClick}
                  >
                    Registration
                  </Link> */}
                  </li>
                  {/* <li>
                    <Link
                      className="nav-links"
                      to="/Howitworks"
                      onClick={this.handleClick}
                    >
                      How it Works
                    </Link>
                  </li> */}
                  <li style={{ display: "flex" }}>
                    <div className="navbaricons">
                      <Link to="/Inbox" onClick={this.handleClick}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-envelope-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                        </svg>
                        <div className="counter">2</div>
                      </Link>
                    </div>
                    <div
                      className="navbaricons dropdown"
                      style={{
                        alignContent: "center",
                        margin: "auto 20px",
                      }}
                    >
                      <Link to="/Notifications" onClick={this.handleClick}>
                        <div>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-bell-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                          </svg>
                        </div>
                      </Link>
                      {/* dropdown list */}
                      <div
                        className="dropdown-content"
                        style={{
                          width: "40rem",
                          position: "absolute !important",
                          backgroundColor: "rgba(255, 255, 255, 0.5)",
                        }}
                      >
                        <Link to="/Messages" onClick={this.handleClick}>
                          you have a message <Button>Mark as Read</Button>
                        </Link>
                        <Link to="/Messages" onClick={this.handleClick}>
                          Notifiaction 2<Button>Mark as Read</Button>
                        </Link>
                        <Link to="/" onClick={this.handleClick}>
                          Notification number 3<Button>Mark as Read</Button>
                        </Link>
                      </div>

                      {/* end of dropdown list */}
                    </div>
                    <div className="dropdown">
                      <div
                        style={{
                          marginLeft: "auto auto",
                          width: "10rem",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {this.state.HaveProfile ? (
                          <Avatar className="dropbtn">
                            <Link to="/Profile">
                              <img
                                src={this.state.profilePic}
                                alt="P"
                                width="50px"
                                height="50px"
                                style={{ objectFit: "cover" }}
                              ></img>
                            </Link>
                          </Avatar>
                        ) : (
                          <Avatar className="dropbtn">
                            {/* {isLoginFromGoogle ? (
                            <img
                              src={localStorage.getItem("profilepicfromgoogle")}
                              width="150px"
                            ></img>
                          ) : ( */}
                            {username.charAt(0).toLocaleUpperCase()}

                            {/* )} */}
                          </Avatar>
                        )}

                        <Typography
                          style={{
                            textAlign: "center",
                            padding: 0,
                            margin: "1rem",
                            display: "flex",
                            cursor: "pointer",
                            width: "auto",
                          }}
                        >
                          {username}
                          {/* {Firstname} */}

                          {/* {lastname} */}
                        </Typography>
                      </div>

                      <div
                        className="dropdown-content"
                        style={{
                          width: "20rem",
                          position: "absolute !important",
                          backgroundColor: "rgba(255, 255, 255, 0.5)",
                          zIndex: "1000",
                        }}
                      >
                        <Link to="/Profile" onClick={this.handleClick}>
                          View profile
                        </Link>
                        <Link to="/Messages" onClick={this.handleClick}>
                          Messaging
                        </Link>
                        <Link to="/InvoicBuilder" onClick={this.handleClick}>
                          Invoicing
                        </Link>

                        {Admin ? (
                          <Link to="/Admindashboard" onClick={this.handleClick}>
                            Admin dashboard{" "}
                          </Link>
                        ) : null}

                        <Link to="/" onClick={logout}>
                          {" "}
                          Logout
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>

                {/* <Link to="/Home" className="wagtang"></Link> */}
              </div>
            )}
          </div>
        </nav>
      </>
    );
  }
}

export default Navbar;
