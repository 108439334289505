import React, { useEffect, useState } from "react";
import Particles from "react-particles-js";
import particlesConfig from "./particle-config";

import Videobackground from "../components/video/Videobackground.mp4";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { typography } from "@material-ui/system";
import { left } from "@popperjs/core";
import { NavLink } from "react-router-dom";
import MTable from "../components/MTable";
import { Component, props } from "react";
import "./index.css";
import "./HomeNew.css";
import Carousel from "react-bootstrap/Carousel";
import Footer from "../components/Footer";
import ReactDOM from "react-dom";
import MessengerCustomerChat from "react-messenger-customer-chat";
import PageLoader from "../components/PageLoader";
import { Parallax } from "react-parallax";
const HomeNew = () => {
  const [isloading, setisloading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setisloading(false);
    }, 2500);
  });

  // ParticlesJS Config.

  return (
    <>
      <div className="mainHomewrapper">
        {/* <div className="container-fluid"> */}
        {isloading ? (
          <PageLoader />
        ) : (
          <>
            <div className="row">
              <Particles
                className="OpacityIn"
                style={{ position: "fixed" }}
                // height="60%"
                // height="auto"
                // width="100%"
                params={particlesConfig}
              />
              <Parallax
                blur={{ min: -15, max: 15 }}
                className="row OpacityIn"
                bgImage="backgroundforHome.png"
                strength={300}
                style={{
                  width: "100%",
                  height: "380px",
                  margin: "0 auto",
                  zIndex: "1",
                  objectFit: "cover",

                  position: "sticky",
                }}
              >
                <div
                  style={{
                    zIndex: "-1",

                    alignContent: "center",
                    marginTop: "4.6rem",
                    alignItems: "center",
                    display: "flex",
                    backgroundColor: "gray",
                  }}
                  className="fadeBG"
                >
                  {/* <img
                  src="backgroundforHome.png"
                  style={{
                    width: "100%",
                    height: "380px",
                    margin: "0 auto",
                    zIndex: "1",

                    position: "sticky",
                  }}
                ></img> */}
                </div>
              </Parallax>
              <div
                className="Fcodesdropshadow OpacityIn"
                style={{ zIndex: "999" }}
              >
                <h1
                  className="mview"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  GLOBAL IMPACT
                </h1>
              </div>
              <div
                className="row OpacityIn "
                style={{
                  backgroundColor: "#1597E5",
                  width: "100%",
                  // width: "auto",
                  height: "250px",
                  alignContent: "center",
                  zIndex: "-2",
                }}
              >
                <h3
                  className="subtitlestyle OpacityIn Fcodesdropshadow text-capitalize"
                  data-aos="zoom-in"
                >
                  Outsourcing “Superiority Achieved through Certified Global
                  Impact Freelancers”
                </h3>

                <h5
                  className="subtitlestyle2 OpacityIn Fcodesdropshadow"
                  data-aos="zoom-in"
                >
                  GET CERTIFIED TODAY FOR FREE AND RECEIVE A FREE KEY TO YOUR
                  OWN PERSONAL WEBPAGE
                </h5>
              </div>
            </div>

            <div className="container">
              {/* <div className="m1" style={{ width: "150vh", margin: "5rem auto" }}> */}
              <div className="row">
                <div className="d-flex flex-row flex-sm-wrap m-auto py-2 ">
                  <div
                    className="col p-3  m-auto"
                    data-aos="fade-right"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  >
                    <img
                      style={{
                        margin: "1rem 1rem",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                      className="shadow-lg"
                      width={600}
                      height={400}
                      src="freelancer pic.jpg"
                    ></img>
                  </div>

                  <div className="col p-3" data-aos="fade-up">
                    <div>
                      <p className="Typolblack">
                        Global Impact Solutions is a non-profit organization
                        focused on creating opportunities for BPO professionals
                        throughout the country who were forced to leave their
                        homes, families, and communities just to find
                        sustainable work in the big cities.
                      </p>
                    </div>

                    <div>
                      <Typography className="Typolblack">
                        This is achieved through the Global Impact relocation
                        program. Displaced SEASONED BPO professionals are given
                        all the support needed to become a Global Impact
                        Certified Freelancer. In this relocation program upon
                        qualifying, Global Impact will help these individuals
                        acquire their first client that will provide a very
                        attractive and sustainable income. GIS will also help
                        with relocation costs, tools, training and Internet
                        Connectivity for them to be successful in their new
                        career as a Global Impact Certified Freelancer.
                      </Typography>
                    </div>

                    <div>
                      <Typography className="Typolblack">
                        Global Impact Solutions also provides opportunities for
                        season BPO professionals who are looking to become a
                        certified Freelancer that will allow them to work at
                        home and be their own business
                      </Typography>
                    </div>
                  </div>
                </div>

                {/* start row */}

                <div className="row">
                  <div className="col p-3">
                    <div>
                      <p
                        className="Typolblack"
                        data-aos="fade-right"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                      >
                        Global Impact also helps advance the existing Freelancer
                        community by providing them with FREE tools created
                        specifically for Freelancing professionals to help them
                        become more organized, productive and professional as
                        well as expose them to more opportunities and manage
                        their own professional online profile. FREELANCING is
                        the future and GLOBAL IMPACT is here to assist the
                        Freelance community thrive by giving them all the tools
                        and opportunities to make sure they are successful and
                        have a great future.
                      </p>
                    </div>

                    <div>
                      <Typography
                        className="Typolblack"
                        data-aos="fade-right"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                      >
                        All this is made possible by donations and support
                        received by outsourcing clients looking to support our
                        efforts and fill their outsourcing needs with seasoned
                        GLOBAL IMPACT CERTIFIED FREELANCES. This allows Global
                        Impact to provide most all these tools and support for
                        free to existing Freelancers.
                      </Typography>
                    </div>

                    <div>
                      <Typography
                        data-aos="fade-right"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                        className="Typolblack"
                      >
                        GET CERTIFIED FOR FREE TODAY AND IGNIGHT YOUR BUSINESS
                      </Typography>
                    </div>
                  </div>
                  <div
                    className="col  m-lg-auto"
                    data-aos="fade-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  >
                    <img
                      style={{ margin: "1rem 1rem", objectFit: "cover" }}
                      className="shadow-lg"
                      width={600}
                      height={400}
                      // src="letDO.png"
                      src="https://www.theladders.com/wp-content/uploads/sales-meeting-191002.jpg"
                    ></img>
                  </div>
                </div>

                {/* end row  */}

                {/* start row */}

                <div
                  data-aos="fade-right"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                  className="row"
                >
                  <div
                    className="col m-lg-auto "
                    data-aos="fade-right"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                  >
                    <img
                      className="rounded"
                      width={600}
                      height={400}
                      src="pic3.png"
                    ></img>
                  </div>
                  <div className="col p-3 ">
                    <div data-aos="fade-up">
                      <Typography className="Typolblack">
                        The gisupport.org platform delivers everything A
                        FREELANCER NEEDS to create their own profile page, get
                        onboarded with new clients and operate their own
                        business efficiently and effectively. The gisupport.org
                        platform offers seasoned BPO professional an opportunity
                        to become a "GLOBAL IMPACT CERTIFIED FREELANCER” all
                        online and print out their certificate upon completion
                        of the free certification program which will set you
                        apart from all other freelancers and is all totally
                        free.
                      </Typography>
                    </div>
                    <div>
                      <Typography className="Typolblack">
                        Becoming a “Global Impact Certified Freelancer” will
                        also open an opportunity to join the Global Impact
                        Support Community where you will find comradery with
                        pears and opportunities to interact at special events
                        produced and supported by doners.
                      </Typography>
                    </div>

                    <div>
                      <Typography className="Typolblack">
                        If you are interested in becoming a Global Impact
                        Certified Freelancer, simply start by registering on
                        this website.
                        <NavLink
                          to="/sign-up"
                          style={{ textDecoration: "none" }}
                        >
                          {" "}
                          CLICK HERE{" "}
                        </NavLink>
                        Global Impact Solutions will review your information and
                        respond with the next steps to assist with achieving
                        your individual goals.
                      </Typography>
                    </div>
                  </div>
                </div>

                {/* end row  */}
              </div>
            </div>
            <div
              className="row"
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto auto",

                alignContent: "center",
                margin: "0",
                padding: "0",
              }}
            >
              <div
                className="col OpacityIn Fcodesdropshadow"
                style={{ alignContent: "center", alignItems: "center" }}
                // data-aos="flip-left"
                // data-aos-easing="ease-out-cubic"
                // data-aos-duration="2000"
              >
                <center>
                  {/* <img src="sampic.png" width="60%" alt="image"></img> */}
                  {/* original <Carousel fade style={{ margin: "10rem auto", height: "80vh" }}>
                   */}
                  <Carousel
                    fade
                    style={{ margin: "10rem auto", height: "80vh" }}
                  >
                    <Carousel.Item interval={5000}>
                      <img
                        className="d-block w-100"
                        src="sampic.png"
                        alt="First slide"
                      />
                      <Carousel.Caption>
                        {/* <h3>First slide label</h3>
                  <p>this is only a sample pic</p> */}
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={5500}>
                      <img className="d-block w-100" src="Home picture1.png" />
                      <Carousel.Caption>
                        {/* <h3>Second slide label</h3>
                  <p>this is the second sample pic</p> */}
                      </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item interval={5500}>
                      <img
                        className="d-block w-100"
                        src="sampic.png"
                        alt="Third slide"
                      />
                      <Carousel.Caption>
                        {/* <h3>Third slide label</h3>
                    <p>this is only a sample 3rd picture</p> */}
                      </Carousel.Caption>
                    </Carousel.Item>
                  </Carousel>
                </center>
              </div>
              {/* <div className="col">
          <img
            src="https://images.unsplash.com/photo-1627814904633-03f37c7a7086?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80"
            alt="image"
            width="300px"
          ></img>
        </div>
        <div className="col">
          <img
            src="https://images.unsplash.com/photo-1627814904633-03f37c7a7086?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80"
            alt="image"
            width="300px"
          ></img>
        </div> */}
            </div>
            {/*footer here  */}
            <MessengerCustomerChat
              pageId="102512904639622"
              appId="458791189047676"
            />
            <Footer />
          </>
        )}
      </div>
    </>
  );
};

export default HomeNew;
