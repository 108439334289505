import React from "react";
import {
  MailTwoTone,
  LockTwoTone,
  ProfileTwoTone,
  UserOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import { Input, Button } from "antd";
import { Link, NavLink } from "react-router-dom";
import "./Landingpage.css";
import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import PageLoader from "../components/PageLoader";
import MessengerCustomerChat from "react-messenger-customer-chat";

import { ArrowDropDown } from "@material-ui/icons";

function Landingpage() {
  // const [isloading, setisloading] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setisloading(false);
  //   }, 2500);
  // });
  return (
    <>
      {/* {isloading? (<PageLoader/> ):( null)} */}
      <div
        className="container-fluid"
        style={{ position: "relative", marginTop: "5rem" }}
      >
        <section id="section1">
          <div
            // class="row align-items-center justify-content-center "
            className="row Autowidth"

            //  backgroundColor: "#1597e5"
          >
            <div
              className="arrowdown"
              onClick={() => {
                window.location.href = "#section2";
              }}
            ></div>
            <div className="col-lg-6 align-items-center justify-content-center TitleHeaderwrapper ">
              <div
                className="row"
                style={{
                  marginTop: "10rem",
                  marginLeft: "3rem",
                  marginRight: "8rem",

                  width: "80%",
                  // justifyContent: "center",
                  // alignContent: "center",
                  // alignItems: "center",
                }}
              >
                <h1
                  className="titlemainHome"
                  style={{
                    color: "#fff",
                    fontWeight: "bolder",
                    width: "664px",
                    fontStyle: "normal",
                    fontSize: "40px",
                  }}
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-easing="ease-in-sine"
                >
                  Outsourcing “Superiority <br />
                  Achieved through Certified
                </h1>

                <h1
                  className="subtitle"
                  data-aos="fade-right"
                  data-aos-delay="600"
                  data-aos-easing="ease-in-sine"
                  style={{ fontSize: "40px", width: "664px" }}
                >
                  {" "}
                  Global Impact Freelancers”
                </h1>
                <h3
                  className="subtitle2"
                  data-aos="fade-left"
                  data-aos-delay="600"
                  data-aos-easing="ease-in-sine"
                  style={{
                    width: "600px",
                    height: "54px",
                    fontSize: "23px",
                  }}
                >
                  Get Certified today for free and receive a free key
                  <br /> to your own personal webpage
                </h3>
                <ul className="m-3 p-3 ulcl ">
                  <li
                    data-aos="fade-left"
                    data-aos-delay="600"
                    data-aos-easing="ease-in-sine"
                  >
                    Exposure to global client opportunities
                  </li>

                  <li
                    data-aos="fade-left"
                    data-aos-delay="1000"
                    data-aos-easing="ease-in-sine"
                  >
                    Your own personalized custom web page
                  </li>
                  <li
                    data-aos="fade-left"
                    data-aos-delay="1200"
                    data-aos-easing="ease-in-sine"
                  >
                    Free access to all the tools developed for freelancers to
                    operate efficiently and professionaly
                  </li>

                  <li
                    data-aos="fade-left"
                    data-aos-delay="1400"
                    data-aos-easing="ease-in-sine"
                  >
                    Ongiong support designed just for global impact certified
                    freelancers.
                  </li>

                  <li
                    data-aos="fade-left"
                    data-aos-delay="1600"
                    data-aos-easing="ease-in-sine"
                  >
                    Special discounts on the most utilized products and services
                    by freelancers.
                  </li>
                  <a
                    href="#section2"
                    data-aos="fade-left"
                    data-aos-delay="1800"
                    data-aos-easing="ease-in-sine"
                  >
                    more
                  </a>
                </ul>

                {/* <NavLink
                to="/sign-up"
                style={{ textDecoration: "none" }}
                data-aos="fade-top"
                data-aos-delay="600"
                data-aos-easing="ease-in-sine"
              > */}
                <NavLink
                  to="/RegistrationProcess"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    padding: "0",
                  }}
                >
                  <p style={{ opacity: "0" }}></p>
                  <Button
                    variant="contained"
                    data-aos="fade-up"
                    data-aos-delay="800"
                    data-aos-easing="ease-in-sine"
                    style={{
                      backgroundColor: "#F7931E",
                      width: "196px",
                      height: "53px",
                      borderStyle: "none",
                      borderRadius: "15px",
                      textSize: "20px",
                      justifyContent: "center",
                      textAlign: "center",
                      marginTop: "2rem",
                      marginLeft: "1rem",
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      REGISTER
                    </p>
                  </Button>
                </NavLink>
              </div>
            </div>

            <div
              className="col-lg-6 withpic justify-content-end displayNoneifMobile"
              style={{
                position: "absolute",
                height: "100vh",
                width: "65vw",
                right: "0",
                backgroundImage: `url(homepic.jpg)`,
                backgroundAttachment: "scroll",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                //   objectFit: "cover",
                // marginLeft: "-5rem",
                zIndex: "-1",
              }}
            >
              {/* <img
              src="homepic.jpg"
              style={{ objectFit: "cover", height: "50rem", zIndex: "1" }}
            /> */}
            </div>

            <a
              href="#section2"
              style={{
                color: "white",
                textDecoration: "none",
              }}
              className="arrowdownbutton"
            >
              <div class="box">
                <span></span>
                <span></span>
                <span></span>
              </div>
              {/* <p
                style={{
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                See more
              </p> */}
            </a>
          </div>
        </section>

        <section id="section2" className="h-100 position-relative">
          <div
            className="container "
            style={{
              marginTop: "10rem",
              marginBottom: "10rem",
            }}
          >
            <div className="row m-3" style={{ height: "auto" }}>
              <div className="col-lg-4">
                <img className="mt-3" src="11.jpg" width="100%"></img>
              </div>
              <div className="col-lg-8 pl-5 mt-3">
                <p className="fs-5  font-weight-bold ">
                  Global Impact Solutions is a non-profit organization focused
                  on creating opportunities for seasoned BPO professionals
                  throughout the country who were forced to leave their homes,
                  families, and communities just to find sustainable work in the
                  big cities. This is achieved through "GLOBAL IMPACT RELOCATION
                  PROGRAM". Displaced SEASONED BPO professionals are given all
                  the support needed to become a Global Impact Certified
                  Freelancer. In this relocation program upon qualifying, Global
                  Impact will help these individuals acquire their first client
                  that will provide an attractive and sustainable income. GIS
                  will also help with relocation costs, tools, training,
                  internet connectivity and more to be successful in their new
                  business as a Global Impact Certified Freelancer.
                </p>
              </div>
            </div>
            <div className="row m-3" style={{ height: "auto" }}>
              <div className="col-lg-4">
                <img className="mt-3" src="22.jpg" width="100%"></img>
              </div>
              <div className="col-lg-8 pl-5 mt-5">
                <p className="fs-5  font-weight-bold">
                  The gisupport.org platform delivers everything A FREELANCER
                  NEEDS to create their own profile page, get onboarded with new
                  clients and operate their own business efficiently and
                  effectively. The gisupport.org platform offers seasoned
                  FREELANCING BPO professionals an opportunity to become a
                  "GLOBAL IMPACT CERTIFIED FREELANCER” all online and print
                  their certificate upon completion of the free certification
                  program which will set them apart from all other freelancers
                  and is all totally free.
                </p>
              </div>
            </div>
            <div className="row  m-3 pt-3" style={{ height: "auto" }}>
              <div className="col-lg-4">
                <img className="mt-3 img" src="33.jpg" width="100%"></img>
              </div>
              <div className="col-lg-8 pl-5 mt-3">
                <p className="fs-5 font-weight-bold">
                  Becoming a “Global Impact Certified Freelancer” will also open
                  an opportunity to be a part of the Global Impact Support
                  Community where you will find comradery with pears and
                  opportunities to interact at special events produced and
                  supported by doners.
                </p>
                <p className="fs-5  font-weight-bold">
                  If you are interested in becoming a Global Impact Certified
                  Freelancer, simply start by registering on this website.{" "}
                  <NavLink to="/sign-up" style={{ textDecoration: "none" }}>
                    CLICK HERE
                  </NavLink>{" "}
                  Global Impact Solutions will review your information and
                  respond with the next steps to assist with achieving your
                  individual goals.
                </p>
                {/* <p className="fs-5 font-weight-bold">
                  If you are interested in becoming a Global Impact Certified
                  Freelancer, simply start by registering on this website.{" "}
                  Global Impact Solutions will review your information and
                  respond with the next steps to assist with achieving your
                  individual goals.
                </p> */}
              </div>
            </div>
            <a
              href="#section3"
              style={{
                color: "white",
                textDecoration: "none",
              }}
              className="arrowdownbutton"
            >
              <div className="box2">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </div>
        </section>
        <section id="section3">
          <div className="row" style={{ backgroundColor: "#eaeaec" }}>
            {/* <div className="col-lg-1"></div> */}
            <div
              className="col-lg-6 "
              style={{ overflow: "hidden" }}
              // style={{
              //   backgroundImage: `url(signup.png)`,
              //   backgroundAttachment: "scroll",
              //   backgroundRepeat: "no-repeat",
              //   backgroundSize: "cover",
              // }}
            >
              <img
                src="signup.png"
                // style={{ width: "655px", height: "437px" }}
                style={{ width: "50vw", objectFit: "cover" }}
              ></img>
            </div>
            <div
              className="col-lg-6 justify-content-center align-items-center text-center"
              style={{ height: "auto", padding: "2rem" }}
            >
              <p className="getcertified" style={{ fontSize: "40.5px" }}>
                Get Certified for{" "}
                <font color="#f7941f" fontSize="40.5px">
                  FREE
                </font>
              </p>
              <p style={{ fontSize: "23px" }} className="getcertifiedsub">
                and receive a free key to your own personal webpage.
              </p>
              <div className="row mt-3">
                <center>
                  <Input
                    size="large"
                    prefix={<ProfileTwoTone />}
                    placeholder="Username"
                    style={{
                      width: "100%",
                      height: "70px",
                      fontSize: "21px",
                    }}
                  />
                </center>
              </div>
              <div className="row  mt-3">
                <center>
                  <Input
                    size="large"
                    placeholder="Email"
                    style={{
                      width: "100%",
                      height: "70px",
                      fontSize: "21px",
                    }}
                    prefix={<MailTwoTone />}
                  />
                </center>
              </div>
              <div className="row  mt-3">
                <center>
                  <Input
                    size="large"
                    type="password"
                    style={{
                      width: "100%",
                      height: "70px",
                      fontSize: "21px",
                    }}
                    prefix={<LockTwoTone />}
                  />
                </center>
              </div>
              <div>
                <Button
                  block
                  shape="round"
                  // icon={<DownloadOutlined />}
                  size="large"
                  className="mt-3 align-content-center justify-content-center"
                  style={{
                    margin: "1rem auto !important",
                    backgroundColor: "#f7941f",
                    color: "#fff",
                    width: "263px",
                    height: "48px",
                  }}
                >
                  <NavLink to="/RegistrationProcess">
                    <p style={{ fontSize: "20px" }}> Create account</p>
                  </NavLink>
                </Button>
              </div>
            </div>
            {/* <div className="col-lg-2"></div> */}
          </div>
        </section>
        <MessengerCustomerChat
          pageId="102512904639622"
          appId="458791189047676"
        />
        <Footer />
      </div>
    </>
  );
}

export default Landingpage;
