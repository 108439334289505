import React from "react";
import { RiUserSearchFill } from "react-icons/ri";
import {
  LineStyle,
  Timeline,
  TrendingUp,
  PermIdentity,
  Storefront,
  AttachMoney,
  BarChart,
  MailOutline,
  DynamicFeed,
  ChatBubbleOutline,
  WorkOutline,
  Report,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import "./Sidebar.css";
import { AiFillCreditCard, AiFillHome } from "react-icons/ai";

export default function Sidebar() {
  const username = localStorage.getItem("yourusername");
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu menu">
          {/* <h3 className="sidebarTitle menuItem uno">
            Welcome Admin {username}
          </h3> */}

          <ul className="sidebarList">
            <Link to="/Admindashboard" className="link">
              <li className="sidebarListItem menuItem uno ">
                <AiFillHome className="sidebarIcon" />
                Home
              </li>
            </Link>
            <Link to="/Users" className="link">
              <li className="sidebarListItem menuItem uno ">
                <PermIdentity className="sidebarIcon" />
                Users
              </li>
            </Link>
            <Link to="/AdminFreelancers" className="link ">
              <li className="sidebarListItem menuItem dos">
                <Timeline className="sidebarIcon " />
                <span> Freelancers</span>
              </li>
            </Link>
            <Link to="/Hirings" className="link ">
              <li className="sidebarListItem menuItem tres">
                <RiUserSearchFill className="sidebarIcon" />
                Hirings
              </li>
            </Link>
            <Link to="/UserActivities" className="link ">
              <li className="sidebarListItem menuItem tres">
                <RiUserSearchFill className="sidebarIcon" />
                User Activities
              </li>
            </Link>
            <Link to="/AdminInvoices" className="link ">
              <li className="sidebarListItem menuItem tres">
                <AiFillCreditCard className="sidebarIcon" />
                Invoices
              </li>
            </Link>
          </ul>
        </div>
        {/* <div className="sidebarMenu">
          <h3 className="sidebarTitle">Quick Menu</h3>
          <ul className="sidebarList">
            <Link to="/users" className="link">
              <li className="sidebarListItem">
                <PermIdentity className="sidebarIcon" />
                Users
              </li>
            </Link>
            <Link to="/products" className="link">
              <li className="sidebarListItem">
                <Storefront className="sidebarIcon" />
                Products
              </li>
            </Link>
            <li className="sidebarListItem">
              <AttachMoney className="sidebarIcon" />
              Transactions
            </li>
            <li className="sidebarListItem">
              <BarChart className="sidebarIcon" />
              Reports
            </li>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Notifications</h3>
          <ul className="sidebarList">
            <li className="sidebarListItem">
              <MailOutline className="sidebarIcon" />
              Mail
            </li>
            <li className="sidebarListItem">
              <DynamicFeed className="sidebarIcon" />
              Feedback
            </li>
            <li className="sidebarListItem">
              <ChatBubbleOutline className="sidebarIcon" />
              Messages
            </li>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Staff</h3>
          <ul className="sidebarList">
            <li className="sidebarListItem">
              <WorkOutline className="sidebarIcon" />
              Manage
            </li>
            <li className="sidebarListItem">
              <Timeline className="sidebarIcon" />
              Analytics
            </li>
            <li className="sidebarListItem">
              <Report className="sidebarIcon" />
              Reports
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
}
